.site-wrapper {
  overflow: visible !important;
}

a{
    cursor: pointer !important;
}

.swal-icon--custom img {
   width: 88px !important;
   height: 88px !important;
}

.swal-icon.swal-icon--warning {
  border-color: #ffd00d !important;
}

.swal-icon--warning__body, .swal-icon--warning__dot  {
  background-color: #ffd00d !important;
}

@keyframes example {
  0%   {left:60%; top:24%;} 
  25%  {left:55%; top:24%;}
  50%  {left:50%; top:24%;}
  75%  {left:45%; top:24%;}
  100% {left:73%; top:24%;}
  }

  .swal-button-container {
    width: 100%;
  }

  .price-increase .swal-button-container {
    width: 48%;
  }

  .swal-title {
    font-size: 28px !important;
    font-weight: 800 !important;
    color: var(--dark-gray);
    font-family: 'Source Sans Pro', sans-serif;
  }

  .swal-content, .swal-text {
    color: var(--dark-gray);
  }

  .swal-content__span {
    font-family: 'Source Sans Pro', sans-serif;
  }

.swal-button {
  background: #ffd00d;
  color: #333333;
  padding: 10px 55px 10px 20px;
  width: auto;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  border: none;
  text-align: center;
  box-shadow: none;
  font-weight: normal;
  text-shadow: none;
  border-radius: 0;
  width: 100%;
}

.swal-button:hover {
  color: #333333 !important;
  background: #fcdd53 !important;
}

  .swal-button::after {
    content: "\e013";
    font-family: Flaticon;
    transform: rotate(-90deg) !important; 
    font-size: 18px;
    position: absolute;
    font-weight: bold;
    left: 70px;
  }

  .swal-button:active::after {
    content: "\e013";
    font-family: Flaticon;
    transform: rotate(-90deg) !important; 
    font-size: 18px;
    position: absolute;
    font-weight: bold;
    left: 70px;
    animation-name: example;
    animation-duration:4s;
  } 

  .swal-button--ok {
    padding: 10px 24px;
  }

  .swal-button--ok::after {
    content:"";
  }

  .swal-button--continue {
    padding: 10px 10px 10px 10px;
  }

  .swal-button--continue::after {
    content: "\e013";
    font-family: Flaticon;
    transform: rotate(-90deg) !important; 
    font-size: 18px;
    position: absolute;
    font-weight: bold;
    left: unset;
    right: 20px;
  }

  .swal-button--continue:active::after {
    content: "\e013";
    font-family: Flaticon;
    transform: rotate(-90deg) !important; 
    font-size: 18px;
    position: absolute;
    font-weight: bold;
    animation-name: unset;
    animation-duration:unset;
    left: unset;
    right: 5px;
  } 

  
  .swal-button--lookForAnother {
    padding: 10px 10px 10px 10px;
    background: #e0e0e0;
  }

  .swal-button--lookForAnother:hover {
    background: #e9e9e9 !important;
  }

  .swal-button--lookForAnother::after {
    content: "\e013";
    font-family: Flaticon;
    transform: rotate(90deg) !important; 
    font-size: 18px;
    position: absolute;
    font-weight: bold;
    left: 20px;
  }

  .swal-button--lookForAnother:active::after {
    content: "\e013";
    font-family: Flaticon;
    transform: rotate(90deg) !important; 
    font-size: 18px;
    position: absolute;
    font-weight: bold;
    animation-name: unset;
    animation-duration:unset;
    left: 5px;
  } 


  .swal-footer {
    background-color: #f5f5f5;
  }

  .swal-button:focus {
    box-shadow: none !important;
  }
  
  .swal-button:active {
    box-shadow: none !important;
    background-color: #eece5a !important; 
  }

  .price-change-msg-box{
    width: 570px;
  }

  @media(min-width:600px){
    .swal-modal{
      width: 570px !important;
    }
  }

  .deal-box {
    color: #ffd00d !important;
    border-style: solid;
    position: relative;
    border: 1px solid #ffd00d;
    border-right: transparent;
    font-size: 12px;
    margin: 0 0 0 5px;
    padding: 2px;
  }
  .deal-box:after {
    right: -8px;
    top: 2px;
    border: solid transparent;
    content: " ";
    height: 15px;
    width: 15px;
    position: absolute;
    pointer-events: none;
    border-color: #ffd00d;
    border-width: 1px;
    transform: rotate(45deg);
    border-left: transparent;
    border-bottom: transparent;
  }

  @media  (max-width: 767px) {
    .deal-box{
      display: none;
    }
  }

  @media  (max-width: 480px) {
   .deal-box {
     display:inline;
     border:none; 
     font-size:10px; 
     position:absolute; 
     top:24px; 
     left:-2px;
    }
   .deal-box:after {display: none;}
  }

  .subscription .swal-button::after {
    display: none;
  }
  .subscription .swal-button {
    padding: 10px 38.5px 10px 38.5px;
    font-weight: 600;
  }
  @media (min-width: 600px) {
    .subscription {
      width: 610px !important;
    }
  }

  :root {
    --light-gray: #E0E0E0;
    --normal-gray: #A0A0A0;
    --dark-gray: #606060;
    --extra-dark-gray: #444040;
  }

  @media(min-width:375px) and (max-width:500px) {
    .swal-title {
      font-size: 27px !important;
    }

    .swal-content, .swal-button, .swal-text {
      font-size: 15px;
    }

    .price-increase .swal-button-container {
      width: 100% !important;
    }
  }

  @media(min-width:360px) and (max-width:374px) {
    .swal-title {
      font-size: 26px !important;
    }

    .swal-content, .swal-button, .swal-text {
      font-size: 14px;
    }

    .price-increase .swal-button-container {
      width: 100% !important;
    }
  }

  @media(min-width:350px) and (max-width:359px) {
    .swal-title {
      font-size: 24px !important;
    }

    .swal-content, .swal-button, .swal-text {
      font-size: 12px;
    }

    .price-increase .swal-button-container {
      width: 100% !important;
    }
  }