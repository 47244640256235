
.n-header-grid-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    padding: 0px 2%;
  
    .n-header-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 10% 22% 13% 18% 21% minmax(50px, 137px) 17px; //8% 15% 11% 28% 21% minmax(50px, 137px) 17px;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        ". . . . . . ."
        "time destination stops airline price dep-time toggle-icon"
        "tomorrow country . airline price-notation . .";
    }
  
    .time {
      grid-area: time;
    }
  
    .tomorrow-text {
      grid-area: tomorrow;
    }
  
    .destination {
      grid-area: destination;
    }
  
    .country-text {
      grid-area: country;
    }
  
    .n-stops {
      grid-area: stops;
    }
  
    .n-airline {
      grid-area: airline;
    }
  
    .n-price {
      grid-area: price;
    }

    .n-price-notation{
      grid-area: price-notation;
      font-size: 14px !important;
    }

    .n-price-top-deal {
      .deal-tag {
        font-size: 8px;
        padding: 0px 3px;
        padding-bottom: 1px;
        border: 1px solid #ffd00d;
        border-radius: 4px;
        color: #ffd00d;
      }
    }

    .n-price-container{
      display: flex;
      .n-text-box-size-with-deal {
        margin-right: 5px;
      }
      .for-desktop {
        line-height: 18px;
      }
      .deal-img {
        width: 30px;
        position: absolute;
        margin-top: -5px;
        margin-left: 5px;
      }
      .deal-tag {
        font-size: 10px;
        padding: 0px 3px;
        padding-bottom: 1px;
        border: 1px solid #ffd00d;
        border-radius: 4px;
        color: #ffd00d;
      }
    }

    .n-price-notation {
      .deal-img {
        width: 21px;
        position: absolute;
        margin-top: -8px;
        transform: rotate(45deg);
      }
    }

    .n-price-sm, .n-dep-time-sm, .n-dep-time-notation, .n-price-top-deal{
      display: none;
    }

    .n-dep-time-header{
      grid-column: dep-time / span 2 !important;
    }
  
    .n-dep-time {
      grid-area: dep-time;
    }
  
    .n-toggle-icon {
      grid-area: toggle-icon;
    }
  
    .subtext {
      line-height: 3px;
      color: var(--normal-gray);
      font-size: 0.8em;
      font-weight: 100;

      .seats-left {
        color: #ed373b;
      }
    }
  
    .smaller {
      font-size: 0.8em;
    }
  
    .yellow {
      color: #ffd00d;
    }
  
    .red {
      color: #ed373b;
    }
  
    .grey {
      color: #b2b2b2;
    }
  
    .bold {
      font-weight: 800;
    }
  
    .tiny {
      font-weight: 100;
    }
  
    .line-through {
      text-decoration: line-through;
    }
  
    .boxed {
      border-style: solid;
      border-width: 1px;
      padding: 2px;
      margin: 0 0 0 5px;
    }

    .one{
      margin-left: 1em;
    }
  }

  .n-header-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0px 2%;
  }
  

  @media(max-width: 1010px){
    .n-header-grid-wrapper {
      font-size: 16px;
      padding: 0px 2%;
    
      .n-header-grid {
        grid-template-columns: minmax(81px, 18%) minmax(20%, 30%) minmax(10%, 17%) minmax(120px, 20%) minmax(130px, 137px) 17px;
        grid-template-areas:
          ". . . . . ."
          "time destination stops price dep-time toggle-icon"
          "tomorrow country . price-notation . .";
      }
    }

    .n-airline{
      display: none;
    }

    .top-contact-info{
      display: none;
    }
  }

  @media(max-width: 650px){
    .n-header-grid-wrapper {
      font-size: 15px;
      padding: 0px 2%;
    
      .n-header-grid {
        grid-template-columns: minmax(14.6%, 16%) minmax(21%, 33%) minmax(46px, 14%) minmax(20px, 13%) minmax(58px, 21%) 14px;
        grid-template-areas:
          ". . . top-deal . ."
          "time destination stops price dep-time toggle-icon"
          "tomorrow country . price-notation dep-time-notation .";

          .n-dep-time-desktop{
            display: none;
          }
      
          .n-dep-time-sm{
            display: block;
            grid-area: dep-time;
          }

          .n-dep-time-notation{
            display: block;
            grid-area: dep-time-notation;
          }

          .n-price-desktop{
            display: none;
          }

          .n-price-top-deal{
            display: block;
            grid-area: top-deal;
            height: 8px;
            line-height: 0px;
          }

          .n-price-sm{
            display: block;
            grid-area: price;
          }

          .n-price-notation{
            font-size: 13px !important;
          }
      }
    }
  }
  
  @media(max-width: 500px){
    .n-header-grid-wrapper {
      font-size: 15px;
      .n-header-grid {
        // grid-template-columns: minmax(14.6%, 17%) minmax(22%, 26%) minmax(46px, 14%) minmax(20px, 21%) minmax(58px, 22%) 14px;
        grid-template-columns: minmax(14.6%, 17%) minmax(22%, 30%) minmax(46px, 14%) minmax(20px, 17%) minmax(58px, 22%) 14px;
        .n-price-container{
          .deal-img {
            width: 23px;
            margin-top: 6px;
            margin-left: 18px;
            // transform: rotate(45deg);
          }
        }
        
        .n-price-notation {
          .deal-img {
            margin-top: -7px;
          }
        }
      }
    }

  }
  
  @media(min-width:360px) and (max-width:370px){
    .n-header-grid-wrapper {
      font-size: 13px;
      .n-header-grid {
        //grid-template-columns: minmax(51px, 8%) minmax(21%, 40%) minmax(56px, 14%) minmax(32px, 23%) minmax(58px, 58px) 13px;
        grid-template-columns: minmax(51px, 20%) minmax(20%, 35%) minmax(50px, 13%) minmax(20px, 18%) minmax(70px, 58px) 13px;
      }
      // .subtext {
      //   font-size: 12px;
      // }
      .n-header-grid {
        .n-price-container{
          .deal-img {
            width: 20px;
            margin-top: 6px;
            margin-left: 18px;
          }

          .n-price-notation {
            .deal-img {
              margin-top: -6px;
            }
          }
        }        
      }
    }
  }

  @media(min-width:350px) and (max-width:359px){
    .n-header-grid-wrapper {
      font-size: 12px;
      .n-header-grid {
        //grid-template-columns: minmax(51px, 8%) minmax(21%, 40%) minmax(56px, 14%) minmax(32px, 23%) minmax(58px, 58px) 13px;
        grid-template-columns: minmax(51px, 8%) minmax(15%, 40%) minmax(50px, 14%) minmax(35px, 23%) minmax(70px, 58px) 13px
      }
      // .subtext {
      //   font-size: 12px;
      // }
      .n-header-grid {
        .n-price-container{
          .deal-img {
            width: 20px;
            margin-top: 6px;
            margin-left: 18px;
          }

          .n-price-notation {
            .deal-img {
              margin-top: -6px;
            }
          }
        }        
      }
    }
  }

  @media(max-width: 350px){
    .n-header-grid-wrapper {
      font-size: 12px;
      .n-header-grid {
        grid-template-columns: minmax(51px, 18%) minmax(15%, 40%) minmax(50px, 14%) minmax(25px, 15%) minmax(70px, 58px) 13px;
        .subtext {
          font-size: 10px !important;
        }
      }
    }
  }

  @media(min-width: 1000px) and (max-width: 1200px) {
      .n-header-grid-wrapper {
        .n-header-grid {
          grid-template-columns: 15% 20% 12% 18% 18% minmax(110px, 120px) 17px; //9% 20% 13% 20% 21% minmax(50px, 137px) 17px;
        }
      }
}
