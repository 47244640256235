/* === FONT FACE === */

@font-face {
  font-family: 'FontAwesome';
  src: url('/assets/fonts/fontawesome-webfont.eot');
  src: url('/assets/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/fontawesome-webfont.woff') format('woff'), url('/assets/fonts/fontawesome-webfont.ttf') format('truetype'), url('/assets/fonts/fontawesome-webfont.svg#RobotoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-awesome {
  font-family: 'FontAwesome';
}


/* === COMMON STYLES === */

html {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  margin: 0px;
  padding: 0px;
  background: #fff;
  color: #4a4a4a;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  outline: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clear {
  clear: both;
  height: 0px;
  overflow: hidden;
}

.break {
  clear: both;
  width: 100%;
  height: 1px;
  display: block;
  border-bottom: 1px solid #d1d1d1;
}

.underline {
  text-decoration: underline;
}

.dash-break {
  clear: both;
  width: 100%;
  height: 1px;
  display: block;
  border-bottom: 1px dashed #d1d1d1;
}

.left {
  float: left;
}

.right {
  float: right;
}

a img {
  border: none;
  display: block;
}

a {
  color: #333333;
}

a:hover {
  color: #333333;
}

.yellow-text {
  color: #e8c100;
}

.site-wrapper {
  margin: 0 auto;
  position: relative;
  zoom: 1;
}

.site-container {
  max-width: 1170px;
  width: 100%;
  min-width: 225px;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  z-index: 2;
  position: relative;
}

.site-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: none;
  z-index: 991;
  background: url('/assets/img/backgrounds/fancybox/fancybox_overlay.png');
  opacity: 0.6;
}

.page-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

.site-wrapper.grey-bg.page-body {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.grey-bg {
  background: #f0f0f0;
}

.white-bg {
  background: #fff;
}

h1,
h2,
h3,
h4 {
  color: #4a4a4a;
  font-size: 22px;
  margin: 10px 0px;
  font-weight: 300;
}

.home-page-section-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-section-header-text {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  padding: 0px 15px;
  color: black;
}


/*Android browser fix (arrow icon)*/

@media (min-width: 640px) {
  h1,
  h2,
  h3,
  h4 {
    font-family: 'Source Sans Pro', sans-serif;
  }
}

h5 {
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

h5.space {
  margin: 60px 0 20px;
}

h5.space2 {
  margin: 20px 0;
}

h5 span {
  font-weight: 500;
}

p {
  font-weight: 400;
  font-size: 15px;
  line-height: 180%;
}

.italic {
  font-style: italic;
}

.bold-text {
  font-weight: 600;
}

.center-align {
  text-align: center;
}

.transition-all {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

*:focus {
  outline: none!important;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

p.label,
label {
  color: black;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
  padding: 0px;
  display: block;
  text-align: left;
}

p.label .note {
  font-size: 13px;
  color: var(--dark-gray);
}

p.label .yellow-text {
  font-weight: bold;
}

.required-note {
  font-size: 13px;
  font-weight: 400;
  color: #888888;
  line-height: normal;
  margin: 0px;
}

.required-note .yellow-text {
  font-weight: bold;
}

.default-input {
  background: #fff;
  color: #888;
  border: 1px solid var(--light-gray);
  padding: 10px;
  height: 43px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.default-input:focus {
  border-color: #ffdd41;
  border-width: 2px;
}

.default-textarea {
  background: #fff;
  color: #888;
  border: 1px solid #d1d1d1;
  padding: 10px;
  height: 43px;
  margin-bottom: 15px;
  width: 100%;
  min-height: 130px;
  resize: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.default-textarea:focus {
  border-color: #ffdd41;
}

.default-select {
  background: #fff;
  margin-bottom: 15px;
  width: 100%;
  height: 43px;
}

.default-select select {
  width: 100%;
  background: none;
  border: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

.table-cell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    border-style: solid;
    border-color: #bbbbbb;
    border-width: 1px;
    margin: 3px;
    padding: 0px 2px;
    height: 84px;
    width: 13.5%;
    float: left;
  }

.btn {
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
}

.default-select .bootstrap-select {
  width: 100%;
  border: 1px solid var(--light-gray);
  padding: 3px 0px 3px 0px;
}

.default-select .bootstrap-select button {
  width: 100%;
  color: var(--normal-gray);
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.default-select .bootstrap-select .btn-group.open .dropdown-toggle {
  webkit-box-shadow: 0;
  box-shadow: 0;
}

.default-select .btn-default:hover,
.default-select .btn-default:focus,
.default-select .btn-default.focus,
.default-select .btn-default:active,
.default-select .btn-default.active,
.default-select .open>.dropdown-toggle.btn-default {
  background: #fff;
  box-shadow: none;
}

.default-select .dropdown-menu>li>a:hover {
  background: #ffdd41;
  background: -moz-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffdd41), color-stop(100%, #ffcf03));
  background: -webkit-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -o-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -ms-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: linear-gradient(to bottom, #ffdd41 0%, #ffcf03 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdd41', endColorstr='#ffcf03', GradientType=0);
}

.default-select .flaticon-agree {
  font-weight: bold;
}

input {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

textarea {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

select {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

.yellow-header {
  background: #ffd00d;
}

.yellow-header.page-header {
  margin: 0px;
  padding: 0px 20px;
  border: none;
  height: 90px;
}

.yellow-header.page-header>.container-fluid {
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.yellow-header.page-header i {
  color: #bb9b00;
  font-size: 40px;
  position: absolute;
  top: 11px;
  left: 0px;
}

.yellow-header.page-header h1 {
  margin: 0px 0px 0px 40px;
  color: #222222;
  font-size: 22px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.yellow-header.page-header.text-page i {
  top: -13px !important;
}

.yellow-button {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: relative;
  font-size: 16px;
  /* padding: 10px 55px 10px 20px; */
  padding: 0;
  width: auto;
  font-family: 'Source Sans Pro', sans-serif;
  border: none;
  background: #ffd00d;
}

.yellow-button:hover {
  color: black;
  background: #fcdd53;
}

.yellow-button.full {
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin-top: -10px;
}

.yellow-button.disabled {
  opacity: 0.30;
}

.yellow-button.disabled:hover {
  background: #ffd00d;
}

.yellow-button.grey {
  background-color: #dddddd;
  padding: 10px 0;
  width: 100px;
}

.yellow-button.grey:hover {
  background-color: #e8e8e8;
}

.yellow-button p {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  text-align: left;
  margin: 0px;
  float: left;
  color: #333333;
  -webkit-transition: color 150ms linear;
  transition: color 150ms linear;
}

.yellow-button .btn-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px 0 20px;
  margin-top: 0px;
}

.yellow-button .btn-content i{
  margin-left: 10px;
}

.yellow-button .back-btn-content i{
  margin-right: 10px;
}

.yellow-button .back-btn-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; 
  margin-top: 0px;
}

.yellow-button i {
  color: #333333;
  font-size: 16px;
  /* position: absolute; */
  font-weight: bold;
  /* top: 9px; */
  /* right: 20px; */
  -webkit-transition: color 150ms linear;
  transition: color 150ms linear;
}

.yellow-button i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.yellow-button:hover p,
.yellow-button:hover i {
  color: #333333;
}

a.yellow-button:hover,
a.yellow-button:active {
  text-decoration: none;
  color: #333333;
}

.yellow-button.back {
  background: #e0e0e0;
}

.yellow-button.back:hover {
  background: #e9e9e9;
}

.yellow-button.back i {
  left: 20px;
  right: auto;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.yellow-button.contact i {
  font-size: 27px;
  top: 11px;
  right: 20px;
}

.grey-button {
  display: block;
  color: #9d9d9d;
  cursor: pointer;
  text-align: center;
  position: relative;
  font-size: 16px;
  padding: 15px 20px;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  border: none;
  background: #ececec;
  -webkit-transition: color 150ms linear;
  transition: color 150ms linear;
}

.grey-button:hover {
  text-decoration: none;
  color: #222222;
}

.grey-button p {
  font-size: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-align: left;
  margin: 0px;
  float: left;
}

.col.first {
  padding-left: 0;
}

.col.last {
  padding-right: 0;
}

.col.no-padding {
  padding: 0;
}

i.icon-leftarrowcircle {
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

::selection {
  background: #ffdd41;
  color: #333333;
}

::-moz-selection {
  background: #ffdd41;
  color: #333333;
}

.red-text {
  color: #ed373b !important;
}

.white-text {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  /* max-height: 220px !important; */
  font-size: 15px;
}

.dropdown-menu {
  border-radius: 0;
}

.page-content .inner {
  padding: 10px;
  max-width: 870px;
  margin: 0 auto;
}


/* === TOP BUTTON === */

#toTop {
  color: #7a7a7a;
  font-size: 16px;
  font-weight: bold;
  display: none;
  z-index: 9999;
  position: fixed;
  bottom: 20px;
  right: 15px;
  cursor: pointer;
  -webkit-transition: color 150ms linear;
  -moz-transition: color 150ms linear;
  transition: color 150ms linear;
}

#toTop:hover {
  color: #efc700;
}


/* === ALERTS === */

.alert {
  padding: 15px 20px;
  border-radius: 0px;
}

.alert-danger {
  background: #ee5c5f;
  border-color: #ee373b;
}

.alert-success {
  background: #58bd7b;
  border-color: #32b05e;
}

.alert-warning {
  background: #fcd633;
  border-color: #f4c600;
}

.alert-info {
  background: #4075e9;
  border-color: #1d5ae8;
}

.alert>p,
.alert>ul {
  color: #fff;
  font-size: 16px;
}

.alert.alert-warning>p {
  color: #857013;
}

.alert>p strong {
  margin-right: 5px;
}

.alert .close {
  float: right;
  font-size: 40px;
  font-weight: bold;
  line-height: 29px;
  color: #fff;
  text-shadow: 0;
  opacity: .4;
  filter: alpha(opacity=40);
}


/* === INFO BOX === */

.info-box-container>p {
  position: absolute;
  bottom: 0px;
  right: 5px;
}

.info-box-container a {
  font-size: 13px;
  font-weight: 400;
  color: #888;
  text-decoration: underline;
  margin: 0px 2px;
}

.info-box-container a:hover {
  text-decoration: none;
}

.info-box-container .info-box-trigger {
  font-size: 14px;
}

.info-box {
  display: none;
  max-width: 400px;
  width: 100%;
}

.info-box h5 {
  color: #333333;
  font-size: 22px;
  margin: 0px 0px 15px 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

.info-box table {
  width: 100%;
  vertical-align: top;
}

.info-box table th {
  font-size: 16px;
  color: #555555;
  font-weight: 600;
  padding: 4px 15px 4px 0px;
  vertical-align: top;
}

.info-box table td {
  font-size: 16px;
  color: #888888;
  font-weight: 600;
  padding: 4px 0px 4px 15px;
  vertical-align: top;
}


/* === COOKIES INFO === */

.cookies-info {
  background-color: rgba(17, 17, 17, .85);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 6px 0;
}

.cookies-info p {
  font-size: 14px;
  margin: 2px 0 0 0;
  color: #fff;
}

.cookies-info p a {
  font-weight: 600;
  color: #fff;
}

.cookies-info span.close-cookies {
  background-color: #ffd00d;
  color: #333;
  padding: 4px 7px 5px;
  display: table;
  font-size: 14px;
  cursor: pointer;
  float: right;
}

.cookies-info span.close-cookies:hover {
  color: #ffd00d;
  background-color: #000;
}


/* === STATIC BANNER === */

.static-banner img {
  width: 100%;
  height: auto;
}


/* === AUTO COMPLETE === */

.ui-widget-content.ui-autocomplete {
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  font-weight: normal;
  color: #333;
  border-color: #ffdd41;
  background: #ffdd41;
  background: -moz-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffdd41), color-stop(100%, #ffcf03));
  background: -webkit-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -o-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -ms-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: linear-gradient(to bottom, #ffdd41 0%, #ffcf03 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdd41', endColorstr='#ffcf03', GradientType=0);
}


/* === DEAL ALERTS FORM === */

.dealalert-form .yellow-button {
  margin-bottom: 0px;
}

.dealalert-form .add-destination {
  text-align: right;
  margin: 0px;
  cursor: pointer;
}

.dealalert-form .add-destination:hover {
  text-decoration: underline;
}


/* === HEADER === */

header.site-wrapper {
  background: #fff;
  padding: 5px 20px;
  position: relative;
  z-index: 999;
}

header .logo {
  display: block;
}

header .logo-no-tag {
  width: 192px;
  display: block;
}

header .logo img,
header .logo-no-tag img {
  height: 46px;
  width: 155px;
  display: block;
}

header .logo-subtitle {
  color: #959292;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  bottom: 9px;
  margin: 0 0 0 10px;
}


/* === DESKTOP NAVIGATION === */

nav {
  margin-top: 7px;
}

nav ul {
  padding: 0px;
  margin: 0px;
}

nav ul li {
  list-style: none;
  display: inline-block;
  zoom: 1;
  display: inline;
  margin: 0px 0px 0px 16px;
}

nav ul li a {
  font-family: 'Source Sans Pro', sans-serif;
  color: var(--dark-gray);
  font-size: 16px;
  font-weight: 700;
  position: relative;
}

nav ul li a i {
  font-size: 18px;
  position: absolute;
  top: -9px;
  left: 0px;
}

nav ul li a:hover {
  text-decoration: none;
  color: #ffde48;
}

nav ul li a img {
  float: left;
  padding-right: 8px;
  position: relative;
  top: 1px;
}

nav ul li a span {
  font-family: 'Glyphicons Halflings' !important;
  color: #d4d4d4;
  font-size: 20px;
  margin-right: 5px;
  position: relative !important;
  top: 6px !important;
}

nav ul li a:hover span {
  color: #ffde48;
}


/* === MOBILE NAVIGATION === */

.mobile-menu {
  color: #fff;
  float: right;
  /* margin-top: 11px; */
  display: none;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

a.menu-butt {
  display: block;
  font-size: 25px;
  color: #bababa;
  line-height: 24px;
  text-decoration: none;
  margin: 0px;
}

.sub-menu:hover {
  display: block;
}

.sub-menu {
  overflow: auto;
  background: #fff;
  width: 100%;
  position: absolute;
  left: 0px;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  z-index: 9999;
}

.sub-menu ul {
  width: 100%;
  background: #fff;
  list-style: none;
  padding: 0px;
  margin: 0px;
  border-top: 1px solid #e3e3e3;
}

.sub-menu ul li a {
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  display: block;
  height: auto;
  position: relative;
  text-decoration: none;
  text-align: left;
  color: #999999;
  background: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 16px 20px;
  margin: 0px;
  border-bottom: 1px solid #e3e3e3;
  border-left: none;
}

.sub-menu ul li a span {
  font-family: 'Glyphicons Halflings' !important;
  color: #d4d4d4;
  font-size: 16px;
  margin-right: 5px;
  position: relative !important;
  top: 3px !important;
}

.sub-menu ul li a:hover span {
  color: #ffde48;
}

.sub-menu ul li a:active {
  color: #555555;
  background: #e7e7e7;
}

.sub-menu ul li a span {
  position: relative;
  padding-left: 30px;
}

.sub-menu ul li a i {
  font-size: 20px;
  position: absolute;
  top: -9px;
  left: 0px;
}

.sub-menu ul li a img {
  float: left;
  padding-right: 8px;
  position: relative;
  top: -1px;
}


/* === DEPARTURE BOARD === */

.departure-board-container {
  margin-bottom: 30px;
  max-width: 850px;
  margin: 0 auto;
}

.departure-board-container .yellow-header {
  padding: 0px 20px;
  color: #222222;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  z-index: 4;
}

.departure-board-container .yellow-header .header-container  .col p {
  margin: 0px;
  color: #222222;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.departure-board-container .yellow-header .header-container  .col.departure {
  position: relative;
}

.departure-board-container .yellow-header .header-container  .col.departure i {
  font-size: 64px;
  position: absolute;
  top: -22px;
  left: -9px;
}

.departure-board-container .yellow-header .header-container  .col.departure p {
  margin: 0px 5px 0 82px;
  text-align: center;
}

.departure-board-container .yellow-header .header-container  .col.departure p.mob-view {
  display: none;
}

.departure-board-container .yellow-header .header-container  .col.location,
.departure-board-container .yellow-header .header-container  .col.time {
  border-left: 1px solid #ffde48;
  border-right: 1px solid #f0ca25;
  padding-top: 28px;
  position: relative;
}

.departure-board-container .yellow-header .header-container  .col.location select,
.departure-board-container .yellow-header .header-container  .col.time select {
  padding: 0px;
  width: 100%;
  background: none;
  border: none;
  color: #222222;
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.departure-board-container .yellow-header .header-container  .col button {
  background: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #222222;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.departure-board-container .yellow-header .header-container  .col button .flaticon-agree {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}

.departure-board-container .yellow-header .header-container  .col .dropdown-menu>li>a:hover {
  background: #ffd00d;
}

.departure-board-container .yellow-header .header-container  .col .dropdown-menu>li>a {
  padding-top: 6px;
  padding-bottom: 6px;
  color: #222222;
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.departure-board-container .yellow-header .header-container  .col .dropdown-menu>li>a.child {
  padding-left: 45px;
}

.departure-board-container .yellow-header .header-container  .col .bootstrap-select.open button .flaticon-agree {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.departure-board-container .yellow-header .header-container  .col.date .date-time p {
  color: #867112;
  font-size: 14px;
  text-align: right;
  line-height: 16px;
}

.departure-board-container .yellow-header .header-container  .col.date .filters-trigger .fancybox-filters {
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
  color: #867112;
}

.departure-board-container .yellow-header .header-container  .col.date .search-input {
  width: 45%;
  width: calc(50% + 15px);
  padding: 4px 8px;
  background: #fff;
  margin-right: -15px;
  margin-bottom: 5px;
  font-weight: 300;
  border: none;
}

.departure-board-container .yellow-header .header-container  .col.date .search-input input {
  border: none;
  background: none;
  box-shadow: none;
  width: 87.6923%;
  width: calc(100% - 24px);
}

.departure-board-container .yellow-header .header-container  .col.date .search-input button {
  color: #999999;
  font-size: 14px;
  width: 22px;
  height: 22px;
  display: block;
  line-height: 22px;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.departure-board-container .yellow-header .header-container  .col.date .search-input button:hover {
  color: #333333;
}

.departure-board-container .yellow-header .header-container  .col.date .sort-by-container {
  width: 45%;
  width: calc(50% - 10px);
}

.departure-board-container .yellow-header .header-container  .col.date .filter-container {
  width: 45%;
  width: calc(50% - 10px);
}

.departure-board-container .yellow-header .header-container  .col.date .filter-container li.dropdown-header {
  font-size: 13px;
}

.departure-board-container .yellow-header .header-container  .col.date button.btn {
  padding-left: 0px;
}

#filters-container {
  display: none;
  padding: 10px;
  width: 800px;
}

#filters-container .inner {
  padding: 15px;
  border: 1px solid #e0e0e0;
}

  #filters-container .inner .row.first {
  padding: 0px 0px 15px 0px;
}

#filters-container .inner p.bold-text {
  margin-bottom: 5px;
}

#filters-container .inner p.bold-text i {
  font-size: 19px;
  line-height: 19px;
  margin-right: 3px;
  color: #cccccc;
}

#filters-container .inner p.bold-text span {
  font-weight: 400;
}

#filters-container .inner ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#filters-container .inner ul li {
  margin: 0;
  padding: 0;
}

#filters-container .inner ul li label {
  color: #333333;
  cursor: pointer;
}

#filters-container .inner ul li input {
  margin-right: 5px;
}

#filters-container .inner ul li label:hover span {
  text-decoration: underline;
}

#filters-container .inner .yellow-button {
  margin-top: 25px;
}

#filters-container .show-all {
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

#filters-container .show-all:hover {
  text-decoration: underline;
}

#filters-container .show-all.collapsed:before {
  content: 'Show All Airlines';
}

#filters-container .show-all:before {
  content: 'Show Less Airlines';
}

.departure-board-container .board-titles {
  background: #3a3a3a;
  height: 30px;
  color: #919191;
}

.departure-board-container .board-titles p .mob-view {
  display: none;
}

.departure-board-container .board-titles.sticky {
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 991;
  width: 100%;
  max-width: 1170px;
  background-color: #2c2c2c;
}

.departure-board-container .board-titles.fixed {
  position: absolute;
  top: 0;
  z-index: 4;
  width: 100%;
  max-width: 1170px;
}

.departure-board-container .board-titles p {
  color: var(--normal-gray);
  margin: 0px;
  font-weight: 800;
}

.departure-board-container .board-titles #dest-search p {
  cursor: pointer;
}

.departure-board-container .board-titles #dest-search p i {
  margin-left: 5px;
  font-size: 14px;
  line-height: normal;
}

.departure-board-container .board-titles #dest-search .input-search {
  background: url('/assets/img/backgrounds/grey-lense.png') center left no-repeat #fff;
  display: none;
  width: 100%;
  padding: 3px 3px;
  line-height: normal;
  color: #0b0b0b;
}

.departure-board-container .board-titles #dest-search .input-search.hidden-background {
  background: #fff;
}

.departure-board-container .board-titles #dest-search .input-search input {
  background: none;
  border: none;
  width: calc(100% - 18px);
  line-height: normal;
  color: #0b0b0b;
}

.departure-board-container .board-titles #dest-search .input-search i {
  cursor: pointer;
  margin-top: 1px;
  color: #818181;
  display: none;
}

.departure-board-container .flight-wrapper {
  position: relative;
}

.departure-board-container .flight-wrapper.sticky {
  margin-top: 43px;
}

.departure-board-container .flight-container .flight-summary {
  position: relative;
  height: 55px;
  background: #222222;
  color: #fff;
  border-bottom: 1px solid #505050;
  cursor: pointer;
  display: table;
  width: 100%;
}

.departure-board-container .flight-container .flight-summary.shadow p {
  opacity: 1;
}

.departure-board-container .flight-container .flight-summary.shadow .dep-time p {
  opacity: 1;
}

.departure-board-container .flight-container .flight-summary.shadow .dep-time {
  opacity: 1;
}

.departure-board-container .flight-container .flight-summary:hover {
  background: #2c2c2c;
}

.departure-board-container .flight-container .flight-summary.last {
  border: none;
}

.departure-board-container .flight-container .flight-summary.active {
  z-index: 112;
  border-color: #fff;
  background: white !important;
}
.departure-board-container .flight-container .flight-summary.active:hover {
  background: #f1f1f1 !important;
}
.departure-board-container .flight-container .flight-summary.active span{
  color: rgb(27, 27, 27) !important;
}

.departure-board-container .flight-container .flight-summary p {
  color: #fff;
  margin: 14px 0 0 0;
}

.departure-board-container .flight-container .flight-summary p.one {
  padding-left: 16px;
}

.departure-board-container .flight-container .flight-summary .row .col.destination p {
  color: #ffd00d;
}

.departure-board-container .flight-container .flight-summary .row .col.destination span.time {
  float: left;
  color: #fff;
  margin-right: 34px;
}

.departure-board-container .flight-container .flight-summary .row .col.destination p.tomorrow {
  color: #b2b2b2;
  font-size: 11px;
  font-weight: 100;
  position: absolute;
  top: 18px;
}

.departure-board-container .flight-container .flight-summary .row .col.destination p.country {
  color: #b2b2b2;
  font-size: 11px;
  font-weight: 100;
  position: absolute;
  top: 18px;
  margin-left: 68px;
}

.departure-board-container .flight-container .flight-summary.active p,
.departure-board-container .flight-container .flight-summary.active .row .col.destination span,
.departure-board-container .flight-container  .row .col.price .price,
.departure-board-container .flight-container .flight-summary.active span.mob-remove,
.departure-board-container .flight-container .flight-summary.active .row .col.price p .direction {
  color: rgb(27, 27, 27);
}

.departure-board-container .flight-container .flight-summary .row .col.price p {
  color: #b2b2b2;
}

.departure-board-container .flight-container .flight-summary .row .col.price p .direction {
  color: #fff;
  font-weight: 100;
}

.departure-board-container .flight-container .flight-summary .row .col.price .was-price {
  text-decoration: line-through;
}

.departure-board-container .flight-container .flight-summary .row .col.price .price {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.departure-board-container .flight-container .flight-summary .row .col.price .mob-show,
.departure-board-container .flight-container .flight-summary .row .col.price .mob-remove {
  font-weight: 100;
}

.departure-board-container .flight-container .flight-summary .row .col.dep-time .soon {
  color: #ed373b;
}

.departure-board-container .flight-container .flight-summary .row .col.dep-time .data {
  font-size: 10px;
}

.departure-board-container .flight-container .flight-summary .row .col.dep-time i {
  color: #585858;
  font-weight: bold;
  font-size: 18px;
  -webkit-transition: color 150ms linear;
  -moz-transition: color 150ms linear;
  transition: color 150ms linear;
  margin-top: 12px;
}

.departure-board-container .flight-container .flight-summary .row .col.dep-time span {
  font-weight: 100;
}

.departure-board-container .flight-container .flight-summary.active .row .col.dep-time i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.departure-board-container .flight-container .flight-details-container {
  position: relative;
  padding: 10px 20px 5px;
  background: #fff;
}

.passenger-input-container {
  margin-bottom: 10px;
}

.passenger-input-container.last {
  margin-bottom: 0px;
}

.passenger-input-container label {
  font-size: 15px;
}

.passenger-input-container label span {
  min-width: 55px;
  display: inline-block;
}

.passenger-input-container .passenger-input .passenger-button {
  float: left;
  position: relative;
  cursor: pointer;
  margin: 0px;
  height: 30px;
  width: 30px;
  line-height: normal;
  color: #222;
  font-size: 30px;
  font-weight: 100;
  background: #fff;
  border: 1px solid #a0a0a0;
  padding: 5px 0px;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.passenger-input-container .passenger-input .passenger-button span {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
}

.passenger-input-container .passenger-input .passenger-button.dec span {
  top: -18px;
}

.passenger-input-container .passenger-input .passenger-button:active {
  background: #f7f7f7;
}

.passenger-input-container .passenger-input .passenger-button.disable {
  background: #d6d6d6;
  cursor: default;
  color: #A0A0A0
}

.passenger-input-container .passenger-input input {
  float: left;
  font-size: 15px;
  max-width: 30px;
  width: 100%;
  color: #222;
  text-align: center;
  background: none;
  border: none;
  margin: 0px 10px;
  border: 1px solid #a0a0a0;
  padding: 3px 0 4px;
  border-radius: 0;
  -webkit-appearance: none;
}

.passenger-input-container .passenger-input input.disable {
  color: #a0a0a0;
}

.departure-board-container .number-of-flghts-container {
  background: #3a3a3a;
  text-align: center;
  padding: 7px 15px;
}

.departure-board-container .number-of-flghts-container .note {
  font-size: 15px;
  color: #919191;
  margin: 0px;
}

.departure-board-container .number-of-flghts-container .note a {
  color: #919191;
  margin: 0px 0px 0px 6px;
}

.departure-board-container .number-of-flghts-container .note a.active {
  text-decoration: underline;
}

.departure-board-container .number-of-flghts-container .note a:hover {
  color: #fed300;
  text-decoration: none;
}

.bootstrap-select.open button .flaticon-agree {
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.departure-board-container .number-of-flghts-container .note .btn-group.bootstrap-select {
  width: auto;
  position: relative;
  top: -1px;
}

.departure-board-container .number-of-flghts-container .note .btn-group.bootstrap-select.open button .flaticon-agree {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.departure-board-container .number-of-flghts-container .note .bootstrap-select>.btn {
  width: auto;
  border-radius: 0px;
  border: 1px solid #4e4e4e;
  background: none;
  font-size: 14px;
  color: #777777;
  margin: 0px 5px;
  padding: 1px 5px;
}

.departure-board-container .number-of-flghts-container .note .bootstrap-select>.btn .flaticon-agree {
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
}

.departure-board-container .number-of-flghts-container .note .dropdown-menu>li>a:hover {
  background: #ffdd41;
  background: -moz-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffdd41), color-stop(100%, #ffcf03));
  background: -webkit-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -o-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -ms-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: linear-gradient(to bottom, #ffdd41 0%, #ffcf03 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdd41', endColorstr='#ffcf03', GradientType=0);
}

.departure-board-container .number-of-flghts-container .note .bootstrap-select.btn-group .btn .filter-option {
  width: auto;
}

.departure-board-container .number-of-flghts-container .sort-by-container {
  color: #919191;
  font-size: 15px;
}

.departure-board-container .number-of-flghts-container .sort-by-container .btn-group.bootstrap-select {
  width: auto;
  position: relative;
  top: -1px;
}

.departure-board-container .number-of-flghts-container .sort-by-container .btn-group.bootstrap-select.open button .flaticon-agree {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.departure-board-container .number-of-flghts-container .sort-by-container .bootstrap-select>.btn {
  width: auto;
  min-width: 200px;
  border-radius: 0px;
  border: 1px solid #4e4e4e;
  background: none;
  font-size: 15px;
  color: #919191;
  margin: 0px 5px;
  padding: 1px 5px;
}

.departure-board-container .number-of-flghts-container .sort-by-container .bootstrap-select>.btn .flaticon-agree {
  font-weight: bold;
  float: right;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
}

.departure-board-container .number-of-flghts-container .sort-by-container .dropdown-menu>li>a:hover {
  background: #ffdd41;
  background: -moz-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffdd41), color-stop(100%, #ffcf03));
  background: -webkit-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -o-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -ms-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: linear-gradient(to bottom, #ffdd41 0%, #ffcf03 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdd41', endColorstr='#ffcf03', GradientType=0);
}

.departure-board-container .number-of-flghts-container .sort-by-container .bootstrap-select.btn-group .btn .filter-option {
  width: auto;
}

.departure-board-container .load-more-container {
  background: #2a2a2a;
  text-align: center;
  padding: 0px;
  -webkit-box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.1);
}

.departure-board-container .load-more-container a {
  display: block;
  width: 100%;
  padding: 17px 15px;
}

.departure-board-container .load-more-container a:hover p {
  color: #fed300;
}

.departure-board-container .load-more-container a p {
  display: inline-block;
  color: #777777;
  width: 165px;
  margin: 0 auto;
}

.departure-board-container .load-more-container span {
  font-size: 18px;
  margin-top: 2px;
  display: inline-block;
}

.departure-board-container .load-more-container i {
  font-size: 19px;
  font-weight: bold;
}


/* === CARDS === */

.card {
  background: #fff;
  width: 31.6239%;
  width: calc((100% - 60px)/3);
  margin: 0px 30px 30px 0px;
  float: left;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.card:nth-child(3) {
  margin-right: 0px;
}

.card:nth-child(6) {
  margin-right: 0px;
}

.card .img-wrap {
  overflow: hidden;
  max-width: 100%;
}

.card .img-wrap img {
  width: 100%;
  height: auto;
  display: block;
  backface-visibility: hidden;
}

.card:hover .img-wrap img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.card .inner {
  margin: 15px 15px 5px;
}

.card .inner h3 {
  margin-bottom: 20px;
  min-height: 48px;
}

.card .inner h3 a {
  color: #333333;
}

/* .card .inner h3 a span {
  color: #bababa;
  font-size: 15px;
} */

.card .inner h3 a:hover {
  text-decoration: none;
  color: #333333;
}

.card .inner table {
  color: var(--dark-gray);
  font-size: 14px;
  text-align: center;
  width: 100%;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.card .inner table,
.card .inner tr,
.card .inner td {
  border: 1px solid #d1d1d1;
  border-collapse: collapse;
}

.card .inner td {
  padding: 7px;
}

.card .inner .price-container {
  margin-top: 20px;
}

.card .inner .price-container p {
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 100;
  display: inline-block;
}

.card .inner .price-container p .was-price {
  text-decoration: line-through;
  color: #bababa;
  font-weight: 100;
}

.card .inner .price-container p .current-price {
  font-weight: 800;
  font-size: 22px;
}

.card .inner .price-container p .per-off {
  color: #ffd00d;
  border-style: solid;
  border-width: 1px;
  padding: 2px 5px;
  margin: 0 0 0 5px;
}

.card .inner .price-container a {
  font-size: 20px;
  color: #bababa;
  font-weight: bold;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: 2px;
}

.card:hover .price-container a {
  text-decoration: none;
  color: #fed300;
}


/* === FULL WIDTH IMG CARD === */

.img-card {
  width: 100%;
  height: 400px;
  display: block;
  /* background: url('/assets/img/temporary/card-img-full-width.jpg') no-repeat center center; */
  background-size: cover;
}

.img-card .card-content {
  background: rgba(17, 17, 17, 0.8);
  width: 32%;
  /* max-width: 370px; */
  color: #fff;
  overflow: auto;
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.img-card .card-content .inner {
  margin: 15px;
}

.img-card .card-content .inner h3 {
  margin-bottom: 24px;
  color: #fff;
}

.img-card .card-content .inner h3 a {
  color: #fff;
}

.img-card .card-content .inner h3 a:hover {
  text-decoration: none;
  color: #fff;
}

/* .img-card .card-content .inner h3 a span {
  color: #bababa;
  font-size: 15px;
} */

.img-card .card-content .inner h3 a:hover {
  text-decoration: none;
}

.img-card .card-content .inner table {
  color: #999999;
  /* font-size: 13px; */
  text-align: center;
  width: 100%;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.img-card .card-content .inner table,
.img-card .card-content .inner tr,
.img-card .card-content .inner td {
  border: 1px solid #414141;
  border-collapse: collapse;
}

.img-card .card-content .inner td {
  padding: 7px;
}

.img-card .card-content .inner .price-container {
  margin-top: 20px;
}

.img-card .card-content .inner .price-container p {
  color: #fff;
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 100;
  margin: 0px;
  display: inline-block;
}

.img-card .card-content .inner .price-container p .was-price {
  text-decoration: line-through;
  color: #888888;
  font-weight: 100;
}

.img-card .card-content .inner .price-container p .current-price {
  font-weight: 800;
  font-size: 22px;
}

.img-card .card-content .inner .price-container p .per-off {
  color: #ffd00d;
  border-style: solid;
  border-width: 1px;
  padding: 2px 5px;
  margin: 0 0 0 5px;
}

.img-card .card-content .inner .price-container a {
  font-size: 30px;
  color: #888888;
  font-weight: bold;
}

.img-card .card-content .inner .price-container a {
  font-size: 20px;
  color: #bababa;
  font-weight: bold;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: 2px;
}

.img-card .card-content .inner .price-container a:hover {
  text-decoration: none;
}

.img-card .card-content:hover .price-container a {
  color: #fed300;
}


/* === CONTACT === */

.page-content .inner h2.contact-text {
  margin: 60px 0px 15px 0px;
}

.contact-page>.inner {
  padding-top: 0px;
}

.contact-text {
  padding: 20px 0px 0px 0px;
}

.contact-text h2,
.text-page .contact-text h2 {
  margin: 0px 0px 10px 0px;
}

.contact-text p {
  margin: 0px;
}

.contact-form {
  margin-top: 40px;
}

.contact-form>p {
  margin-left: -15px;
}

.contact-form .question-subjects {
  margin-bottom: 15px;
}

.contact-form .question-subjects ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.contact-form .question-subjects ul li label input {
  margin-right: 5px;
}


/* === TEXT PAGE === */

.text-page.yellow-header.page-header i {
  font-size: 32px;
  top: 14px;
  left: 0px;
}

.text-page h2 {
  margin: 20px 0px;
}

.text-page ol.normal {
  padding: 0 0 0 20px;
  text-transform: uppercase;
  line-height: 26px;
}

.text-page ol.normal.lower {
  text-transform: none !important;
}

.text-page ul {
  line-height: 26px;
}

.text-page ul.normal {
  padding: 0 0 0 2px;
  text-transform: uppercase;
  line-height: 26px;
  list-style-type: none;
}

.text-page ul.normal.lower {
  text-transform: none !important;
}

.text-page ul.normal.space li {
  margin: 0 0 10px 0;
}

.text-page ul.normal ul {
  list-style-type: none;
  padding: 0 0 0 30px;
}

.text-page p a {
  text-decoration: underline;
}

.text-page .main-header {
  margin: 0 0 11px 0;
  display: table;
}

.text-page .main-header h4 {
  float: left;
}

.text-page .main-header .glyphicon {
  font-family: 'Glyphicons Halflings';
  float: left;
  font-size: 24px;
  margin: 0 15px 0 0;
  position: relative;
  top: 10px;
  color: #ffd00d;
}

.text-page .break {
  margin: 0 0 20px 0;
  display: table;
}


/* === ABOUT === */

.about h2 {
  margin-top: 30px;
}

.about p {
  margin-bottom: 30px;
}


/* === FAQ === */

.faq.yellow-header.page-header i {
  font-size: 32px;
  top: 12px;
  left: 0px;
}

.expand-link {
  cursor: pointer;
}

.question {
  border-bottom: 1px solid #d1d1d1;
  position: relative;
}

.question.first {
  border-top: 1px solid #d1d1d1;
  margin-top: 50px;
}

.question.last {
  margin-bottom: 50px;
}

.question h2 {
  padding: 22px 30px;
  margin: 0;
  display: table;
  font-size: 16px;
  width: 100%;
}

.question:hover h2 {
  background-color: #fafafa;
}

.faq .question h2 {
  font-size: 18px;
  margin-right: 35px;
  margin-bottom: 20px;
  cursor: pointer;
}

.question .collapsed i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.question i {
  color: #ffd00d;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  right: 30px;
  top: 17px;
}

.inner-question:first-of-type {
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid #d1d1d1;
  padding: 10px 40px;
}

.inner-question {
  margin-left: 40px;
  margin-right: 40px;
  border-top: 1px solid #d1d1d1;
  padding: 10px 0px;
  position: relative;
}

.inner-question i {
  top: 10px;
}

.inner-question:first-of-type i {
  right: 70px;
}

.page-content .inner .inner-question h2 {
  font-size: 14px;
  padding: 5px 0;
  background-color: #fff !Important;
}

.inner-question p {
  margin-left: 15px;
  color: #888;
}


/* === BOOKNG FLOW COMMON === */

.booking-flow {
  z-index: 100;
  position: relative;
}

.booking-flow .tab-header {
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.booking-flow .tab-header .yellow-bar {
  background: #ffd00d;
  height: 3px;
  width: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.booking-flow .tab-header .yellow-bar.complete {
  width: 100%;
  z-index: 1;
}

.booking-flow .tab-header .grey-bar {
  background: #f1f1f1;
  height: 3px;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
}

.booking-flow .tab-header .tabs {
  max-width: 1170px;
  width: 100%;
  min-width: 225px;
  position: relative;
  clear: both;
  border-bottom: 0px;
  padding: 0px;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.booking-flow .tab-header .tabs li {
  width: 20%;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 50px;
}

.booking-flow .tab-header .tabs .one-way-step {
  width: 25% !important;
}

.booking-flow .tab-header .tabs li {
  margin-bottom: 0px;
  text-align: center;
  float: left;
  border-top: 3px solid #f1f1f1;
}

.booking-flow .tab-header .tabs li a {
  margin-right: 0px;
  padding: 9px 15px;
  display: block;
  color: #d2d2d2;
  border-radius: 0;
  border: none;
  border-right: 1px solid #f1f1f1;
  display: table;
  width: 100%;
}

.booking-flow .tab-header .tabs li a p {
  color: var(--light-gray);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  margin: 0 auto;
  display: table;
  position: relative;
}

.booking-flow .tab-header .tabs li a i {
  font-size: 15px;
  line-height: 0;
  position: relative;
  top: 4px;
  color: var(--light-gray);
  border: 2px solid var(--light-gray);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  width: 24px;
  height: 24px;
  display: table;
  float: left;
  padding: 10px 0 0 0;
  font-style: normal;
  margin: 0 8px 0 0;
  font-weight: 500;
}

.booking-flow .tab-header .tabs li a span {
  float: left;
}

.booking-flow .tab-header .tabs li a:hover,
.booking-flow .tab-header .tabs li a:active {
  text-decoration: none;
}

.booking-flow .tab-header .tabs li:last-of-type a {
  border-right: none;
}
/* 
.booking-flow .tab-header .tabs li.active {
  border-top: 3px solid #ffd00d;
}

.booking-flow .tab-header .tabs li.active a {
  color: #888888;
}

.booking-flow .tab-header .tabs li.active a p {
  color: #ffd00d;
  font-size: 18px;
}

.booking-flow .tab-header .tabs li.active a i {
  color: #fff;
  border-color: #ffd00d;
  background-color: #ffd00d;
} */

/* .booking-flow .tab-header .tabs li.complete {
  border-top: 3px solid #ffd00d;
}

.booking-flow .tab-header .tabs li.complete a {
  color: #ffd00d;
}

.booking-flow .tab-header .tabs li.complete a p {
  color: #ffd00d;
}

.booking-flow .tab-header .tabs li.complete a i {
  color: #ffd00d;
  border-color: #ffd00d;
  font-size: 12px;
} */

.booking-flow a.return-home {
  color: #999999;
  font-size: 16px;
  position: relative;
  padding-left: 32px;
  display: inline-block;
  margin-top: 12px;
  -webkit-transition: color 150ms linear;
  -moz-transition: color 150ms linear;
  transition: color 150ms linear;
}

.booking-flow a.return-home:hover {
  text-decoration: none;
  color: #e8c100;
}

.booking-flow a.return-home i {
  font-size: 24px;
  position: absolute;
  top: -9px;
  left: 0px;
  font-weight: bold;
}


/* info message */

.booking-flow .info-message {
  /* padding: 10px 0 5px; */
  display: table;
}

.booking-flow .info-message p {
  color: var(--dark-gray);
  position: relative;
  /* top: -5px; */
  display: table-cell;
  margin: 0;
}

.booking-flow .info-message .icon {
  float: left;
  color: #ffd00f;
  border-style: solid;
  border-color: #ffd00f;
  border-width: 1px;
  border-radius: 25px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  text-align: center;
  margin: 0px 12px 0 0;
  font-weight: 800;
}

.booking-flow .info-message .bold {
  font-weight: 700;
  color: #333;
  margin: 0 8px 0 5px;
  float: left;
}

.booking-flow .right-bar.sticky {
  position: fixed;
  top: 20px;
}

.booking-flow .right-bar {
  width: 300px;
  background: #222222;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 19px 21px 7px;
  position: relative;
}

.booking-flow .right-bar .inner {
  margin: 22px 30px;
}

.booking-flow .right-bar h2 {
  color: #fff;
  font-size: 22px;
}

.booking-flow .right-bar h2.expand-link {
  cursor: pointer;
}

.booking-flow .right-bar h2.expand-link i {
  font-size: 16px;
  margin-top: 3px;
  font-weight: bold;
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.booking-flow .right-bar h2.expand-link.collapsed i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.booking-flow .right-bar .break {
  border-color: #959595;
}

.booking-flow .right-bar .dash-break {
  border-color: #959595;
}

.booking-flow .right-bar p {
  color: #b5b5b5;
  line-height: normal;
}

.booking-flow .right-bar .title {
  color: #fff;
  display: table;
  width: 100%;
  font-weight: 400;
  margin: 0 0 7px 0;
  font-size: 15px;
}

.booking-flow .right-bar .title i {
  margin: 0 3px;
  top: 3px;
  position: relative;
}

.booking-flow .right-bar .title span {
  color: #a9a9a9;
}

.booking-flow .right-bar .main-header {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #848484;
  margin: 0 0 5px 0;
  display: table;
  width: 100%;
  padding: 0 0 21px 0;
}

.booking-flow .right-bar .main-header .icon {
  position: absolute;
}

.booking-flow .right-bar .main-header .icon.basket {
  width: 24px;
  top: 19px;
}

.booking-flow .right-bar .main-header h4 {
  color: #fff;
  font-size: 18px;
  margin: 0 0 0 40px;
}

.booking-flow .right-bar .main-header h4 span.mobile-on,
.booking-flow .right-bar .main-header h4 span.mobile-off {
  display: none;
}

.booking-flow .right-bar .basket {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #fff;
}

.booking-flow .right-bar .basket li img.icon {
  float: left;
  width: 23px;
  position: relative;
  top: -3px;
}

.booking-flow .right-bar .basket li.entry {
  margin: 0 0 10px 0;
}

.booking-flow .right-bar .basket li ul.details {
  list-style-type: none;
  margin: -1px 0 0 0;
  padding: 0;
  font-size: 13px;
}

.booking-flow .right-bar .basket li ul.details li {
  margin-bottom: -2px;
}

.booking-flow .right-bar .basket li ul.details li:first-child {
  margin-bottom: -8px;
}

.booking-flow .right-bar .basket li ul.details li .color-1 {
  color: #ffd00d;
}

.booking-flow .right-bar .basket li ul.details li .color-2 {
  color: var(--normal-gray);
  font-weight: 400;
}

.booking-flow .right-bar .basket li ul.details li .icon-rightarrow {
  top: 3px;
  position: relative;
  font-size: 16px;
}

.booking-flow .right-bar .basket li ul.details .bold {
  font-weight: 800;
  color: white;
}

.booking-flow .right-bar .basket li ul.details .light {
  font-weight: 100;
}

.booking-flow .right-bar .nav-buttons {
  padding: 0 10px;
  margin: 6px 0 0 0;
}

.booking-flow .right-bar .nav-buttons .col {
  padding-left: 5px;
  padding-right: 5px;
}

.booking-flow .right-bar .nav-buttons button {
  font-family: 'Source Sans Pro', sans-serif;
  border: none;
  background: #ffd00d;
  width: 100%;
  font-size: 13px;
  padding: 7px 0;
}

.booking-flow .right-bar .nav-buttons button:hover {
  background-color: #fcdd53;
}

.booking-flow .right-bar .persons {
  color: #a9a9a9;
  font-weight: 100;
  font-size: 15px;
  margin: -8px 0 13px 0;
}

.booking-flow .right-bar .persons span {
  color: #fff;
}

.booking-flow .right-bar .passengers {
  color: #fff;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #848484;
  margin: 15px 0 0 0;
  padding: 11px 0 0 0;
}

.booking-flow .right-bar .passengers ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.booking-flow .right-bar .passengers ul li span {
  font-size: 13px;
}

.booking-flow .right-bar .passengers ul li span.date {
  color: #a9a9a9;
  font-weight: 100;
}

.booking-flow .right-bar .costs {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #848484;
  margin: 5px 0 0 0;
  padding: 11px 0 0 0;
}

.booking-flow .right-bar .costs p {
  color: #fff;
  font-weight: 400;
  margin: 0 0 4px 0;
}

.booking-flow .right-bar .costs .price p {
  font-weight: 700;
}

.booking-flow .right-bar .costs #cost-flight .price p,
.booking-flow .right-bar .costs #cost-insurance .price p,
.booking-flow .right-bar .costs #cost-baggage .price p,
.booking-flow .right-bar .costs #cost-fee .price p {
  font-weight: 400;
}

.booking-flow .right-bar .costs p span:not(.price-decimals) {
  color: #a9a9a9;
  font-weight: 100;
  font-size: 15px;
}

.booking-flow .right-bar .costs .color-1 {
  color: #a9a9a9;
  font-weight: 100;
  font-size: 13px;
}

.booking-flow .right-bar .costs #show-breakdown {
  text-decoration: underline;
  top: -1px;
  position: relative;
}

.booking-flow .right-bar .costs #cost-total {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #848484;
  margin: 7px 0 0 0;
  padding: 9px 0 0 0;
}

.booking-flow .right-bar .costs .total p {
  color: #ffd00d;
}

.booking-flow .right-bar .costs .total .price p {
  font-weight: 700;
}

.booking-flow .right-bar .costs .total span:not(.avg-taxes):not(.price-decimals) {
  width: 100%;
  display: table;
  font-size: 11px;
  position: relative;
  top: -9px;
}

.booking-flow .right-bar .costs .total span.left {
  text-align: left;
}

.booking-flow .right-bar .costs .total span.right {
  text-align: right;
}

.booking-flow .right-bar .costs .price {
  text-align: right;
}

.booking-flow .right-bar .price-mobile {
  position: absolute;
  top: 14px;
  right: 10px;
  display: none;
}

.booking-flow .right-bar .price-mobile p {
  font-size: 15px;
  color: #fff;
  font-weight: 700;
}

.booking-flow .go-to-calendar {
  background-color: #ffd00d;
  padding: 10px;
  display: none;
  position: absolute;
  bottom: -60px;
  left: 0;
  cursor: pointer;
}

.booking-flow .go-to-calendar i {
  font-family: 'Glyphicons Halflings';
  font-size: 24px;
}

.booking-flow .go-to-calendar span {
  position: relative;
  top: -5px;
  margin: 0 0 0 7px;
}

.booking-flow .go-to-calendar:hover {
  background-color: #fff;
}

.booking-flow .left-container {
  width: calc(100% - 320px);
  padding: 0 21px 11px;
  background: #fff;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.booking-flow .left-container .main-header {
  /* padding: 22px 0; */
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
}

.booking-flow .left-container .main-header .booking-flow .left-container .main-header.inner {
  padding: 22px 0 0 0;
}

.booking-flow .left-container .main-header h4 {
  margin: 0 0 0 15px;
}

.booking-flow .left-container .main-header h4 i {
  position: relative;
  top: 3px;
}

.booking-flow .left-container .main-header.no-icon h4 {
  margin: 0;
}

.booking-flow .left-container .main-header .icon.calendar {
  width: 30px;
  top: 16px;
}

.booking-flow .left-container .main-header .icon.return2 {
  width: 30px;
  top: 25px;
}

.booking-flow .left-container .main-header .icon.departure {
  width: 30px;
  top: 24px;
}

.booking-flow .left-container .main-header .icon.passenger {
  width: 30px;
  top: 20px;
}

.booking-flow .left-container .main-header .icon.payment {
  width: 30px;
  top: 23px;
}

.booking-flow .left-container .main-header .icon.check {
  width: 30px;
  top: 20px;
}

.booking-flow .left-container .main-header div.info {
  position: absolute;
  top: 23px;
  right: 0;
  color: var(--dark-gray);
}

.booking-flow .left-container .main-header div.info span {
  float: left;
  color: #ffd00f;
  border-style: solid;
  border-color: #ffd00f;
  border-width: 2px;
  border-radius: 25px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  text-align: center;
  margin: 1px 5px 0 0px;
  font-weight: 700;
}

.booking-flow .left-container .main-header .time {
  position: absolute;
  top: 27px;
  right: 0;
}

.booking-flow .left-container .main-header .time img {
  float: left;
  top: 4px;
  position: relative;
  height: 14px;
  width: auto !important;
}

.booking-flow .left-container .main-header .time span {
  float: left;
  color: #000;
  margin: 0 0 0 5px;
  font-weight: 300;
}

.booking-flow .left-container .sub-header {
  width: 100%;
  display: table;
  position: relative;
}

.booking-flow .left-container .sub-header h5 {
  margin: 20px 0 0 0;
}

.booking-flow .left-container .sub-header .required-note {
  position: absolute;
  top: 19px;
  right: 0;
}

.booking-flow .left-container .booking-nav {
  margin: 2px 0 0 0;
}

.booking-flow .left-container h3 span {
  font-size: 13px;
  color: #aaaaaa;
  font-style: italic;
  margin-left: 5px;
}


/* === BOOKING FLOW 1 === */

.booking-flow .right-bar .placeholder {
  height: 270px;
  border-top: 1px solid #333333;
}

.booking-flow .right-bar .placeholder .contents {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.booking-flow .right-bar .placeholder .contents .box {
  width: 65px;
  height: 65px;
  display: block;
  border: 3px dashed #333333;
  margin: 0 auto;
}

.booking-flow .right-bar .placeholder .contents p {
  text-align: center;
  color: #555555;
  font-size: 15px;
  margin-top: 20px;
}

.booking-flow .left-container .inner.passengers .container-fluid {
  border: 1px solid #d1d1d1;
}

.booking-flow .left-container .inner.passengers .col {
  padding: 25px 20px;
  border-right: 1px solid #d1d1d1;
}

.booking-flow .left-container .inner.passengers .col.last {
  border: none;
  padding: 25px 20px;
}

.booking-flow .left-container .inner.passengers .passenger-input-container {
  margin: 0px;
}

.booking-flow .left-container .outer-border {
  border: 1px solid #d1d1d1;
  margin-top: 20px;
}

.booking-flow .left-container .outer-border .inner {
  margin: 22px;
}

.booking-flow .left-container table {
  border-collapse: separate;
  width: 100%;
}

.booking-flow .left-container .flight-info-container tr.last td {
  border-bottom: none;
}

.booking-flow .left-container .flight-info-container .icon {
  margin-right: 10px;
}

.booking-flow .left-container .flight-info-container .icon i {
  font-size: 60px;
  color: #555555;
  line-height: 55px;
}

.booking-flow .left-container .flight-info-container .details .from-label,
.booking-flow .left-container .flight-info-container .details .to-label {
  margin: 0px;
  display: none;
  color: #999999;
  font-size: 13px;
}

.booking-flow .left-container .flight-info-container .time {
  margin-bottom: 0px;
  font-weight: 600;
  line-height: normal;
}

.booking-flow .left-container .flight-info-container .date {
  margin: 0px 5px 0px 0px;
  font-weight: 400;
  line-height: normal;
}

.booking-flow .left-container .flight-info-container .airport {
  margin: 5px 0px;
  font-weight: 600;
  line-height: normal;
}

.booking-flow .left-container .flight-info-container .notes {
  font-size: 13px;
  color: #888888;
  margin: 0px;
  font-weight: 400;
}

.booking-flow .left-container .flight-info-container .time-line {
  width: 9%;
  height: 1px;
  position: relative;
  border-bottom: 1px dashed #d1d1d1;
}

.booking-flow .left-container .flight-info-container .time-line .time-line-wrapper {
  height: 100%;
}

.booking-flow .left-container .flight-info-container .time-line .yellow-dot {
  width: 12px;
  height: 12px;
  background: #fed300;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0px 0px -6px;
  z-index: 2;
  -webkit-border-radius: 40px;
  border-radius: 40px;
}

.booking-flow .left-container .flight-info-container .time-line .grey-dot {
  width: 12px;
  height: 12px;
  background: #cccccc;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0px 0px -6px;
  z-index: 2;
  -webkit-border-radius: 40px;
  border-radius: 40px;
}

.booking-flow .left-container .flight-info-container .time-line .grey-bar.start {
  width: 2px;
  height: 50%;
  background: #ececec;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0px 0px 0px -1px;
  z-index: 1;
}

.booking-flow .left-container .flight-info-container .time-line .grey-bar.end {
  width: 2px;
  height: 50%;
  background: #ececec;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0px 0px 0px -1px;
  z-index: 1;
}

.booking-flow .left-container .flight-info-container .time-line .grey-bar {
  width: 2px;
  height: 100%;
  background: #ececec;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0px 0px 0px -1px;
  z-index: 1;
}

.booking-flow .left-container .flight-info-container .flight-details {
  border-bottom: 1px dashed #d1d1d1;
  width: 53%;
}

.booking-flow .left-container .flight-info-container .flight-details .inner {
  margin-left: 10px;
}

.booking-flow .left-container .flight-info-container .flight-details .details {
  width: 77.4193%;
  width: calc(100% - 72px);
}

.booking-flow .left-container .flight-info-container .airline-details {
  border-left: 1px dashed #d1d1d1;
  border-bottom: 1px dashed #d1d1d1;
  width: 38%;
  position: relative;
}

.booking-flow .left-container .flight-info-container table.direct .airline-details {
  border-bottom: none;
  position: relative;
}

.booking-flow .left-container .flight-info-container .mob.airline-details {
  display: none;
  border-top: 1px dashed #d1d1d1;
  width: 100%;
  border-left: none;
}

.booking-flow .left-container .flight-info-container .airline-details .aireline-logo {
  width: 100%;
  max-width: 25px;
  height: auto;
  display: block;
  margin-bottom: 10px;
}

.booking-flow .left-container .flight-info-container .airline-details p {
  line-height: normal;
  color: #888;
}

.booking-flow .left-container .flight-info-container .airline-details .note {
  font-size: 13px;
  font-weight: 400;
  color: #888888;
}

.booking-flow .left-container .flight-info-container .stop-details {
  border-bottom: 1px dashed #d1d1d1;
}

.booking-flow .left-container .flight-info-container .stop-details p {
  color: #888;
}

.booking-flow .left-container .flight-info-container .duration-details p {
  color: #888;
  margin-left: 9%;
}


/* === BOOKING FLOW 2 === */

.booking-flow #calendar {
  color: #9c9c9c;
  width: 100%;
  margin: 0 auto;
}

.booking-flow #calendar .fc-toolbar {
  border: 1px solid #d1d1d1;
  border-bottom: none;
  padding: 15px;
  margin: 0px;
}

.booking-flow #calendar .fc-toolbar button {
  background: none;
  border: none;
  color: #cccccc;
  font-size: 28px;
  -webkit-transition: color 150ms linear;
  -moz-transition: color 150ms linear;
  transition: color 150ms linear;
}

.booking-flow #calendar .fc-toolbar button:hover {
  color: #fed300;
}

.booking-flow #calendar .fc-toolbar button span {
  line-height: 28px;
  font-weight: bold;
}

.booking-flow #calendar .fc-toolbar h2 {
  margin-top: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.booking-flow #calendar table {
  border-collapse: collapse;
  border-spacing: none;
  padding: 0px;
  margin: 0px;
}

.booking-flow #calendar table,
.booking-flow #calendar td,
.booking-flow #calendar th {
  border: none;
}

.booking-flow #calendar .fc-view>table {
  border: 1px solid #d1d1d1;
  border-left: none;
  border-right: none;
}

.booking-flow #calendar td.fc-day {
  border: 1px solid #d1d1d1;
  border-bottom: none;
}

.booking-flow #calendar .fc-basic-view td.fc-week-number span,
.booking-flow #calendar .fc-basic-view td.fc-day-number {
  font-size: 13px;
  padding: 7px 9px;
}

.booking-flow #calendar th.fc-day-header {
  border: 1px solid #d1d1d1;
  border-bottom: none;
  border-top: none;
  padding: 10px 0px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
}

.booking-flow #calendar .fc-basic-view .fc-body .fc-row {
  min-height: 6em;
}

.booking-flow #calendar .fc-event,
.booking-flow #calendar .fc-event:hover,
.booking-flow #calendar .ui-widget .fc-event {
  cursor: pointer;
  background: none;
  border: none;
  color: #333333;
  text-align: center;
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

.fc-content-skeleton td a {}

.booking-flow #calendar .selected {
  -webkit-box-shadow: inset 0px 2px 1px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: inset 0px 2px 1px 0px rgba(0, 0, 0, 0.07);
  box-shadow: inset 0px 2px 1px 0px rgba(0, 0, 0, 0.07);
  background: #ffdd41;
  background: -moz-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffdd41), color-stop(100%, #ffcf03));
  background: -webkit-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -o-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: -ms-linear-gradient(to top , #ffdd41 0%, #ffcf03 100%);
  background: linear-gradient(to bottom, #ffdd41 0%, #ffcf03 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdd41', endColorstr='#ffcf03', GradientType=0);
}

.booking-flow #calendar .fc-day-number.selected {
  color: #333;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.booking-flow #calendar .fc-day.event-border {
  border: 2px solid #ffdd41;
  border-bottom: 3px solid #ffdd41;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box
}

.booking-flow #calendar .fc-day.event-border.selected {
  border: none;
}

.booking-flow #calendar td.fc-past.fc-day-number {
  color: #d2d2d2;
}

.booking-flow #calendar td.fc-other-month.fc-day-number {
  color: white;
}

.booking-flow #calendar .was-price {
  text-decoration: line-through;
  color: #9a9a9a;
}

.booking-flow #selected-date-return {
  text-align: center;
}

.booking-flow .right-bar .flight-details .header {
  position: relative;
}

.booking-flow .right-bar .flight-details .header i {
  color: #a2a2a2;
  font-size: 53px;
  position: absolute;
  top: -21px;
  line-height: normal;
  left: -10px;
}

.booking-flow .right-bar .flight-details .header h4 {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 50px;
}

.booking-flow .right-bar .flight-details .col {
  margin-top: 7px;
  margin-bottom: 7px;
}

.booking-flow .right-bar .flight-details p {
  margin: 0px;
  line-height: normal;
}

.booking-flow .right-bar .flight-details p.white {
  color: #fff;
}

.booking-flow .right-bar .airline-details p.notes {
  font-size: 13px;
}


/* === BOOKING FLOW 3 === */

.booking-flow .left-container .header i.flaticon-human5 {
  font-size: 40px;
  top: -22px;
}

.booking-flow .left-container.passenger-details a.yellow-button.login {
  padding: 30px 30px;
}

.booking-flow .left-container.passenger-details a.yellow-button.login p {
  display: block;
  float: none;
  margin-right: 40px;
  font-size: 18px;
}

.booking-flow .left-container.passenger-details a.yellow-button.login p.notes {
  font-size: 15px;
  margin-top: 5px;
}

.booking-flow .left-container.passenger-details a.yellow-button.login i {
  font-size: 32px;
  top: 29px;
  right: 30px;
}

.booking-flow .left-container.passenger-details .passenger-inputs .col {
  padding-left: 10px;
  padding-right: 10px;
}

.booking-flow .left-container.passenger-details .passenger-inputs p,
.booking-flow .left-container.passenger-details .passenger-inputs input,
.booking-flow .left-container.passenger-details .passenger-inputs .selectpicker.btn-default,
.booking-flow .left-container.passenger-details .passenger-inputs .dropdown-menu {
  font-size: 16px;
}

.booking-flow .left-container.passenger-details .frequent-flyer-container input.frequent-flyer-trigger {
  margin: 0 5px 10px 0;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account {
  padding: 30px 30px 15px 30px;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account:hover p,
.booking-flow .left-container.passenger-details .yellow-header.create-account:hover .label,
.booking-flow .left-container.passenger-details .yellow-header.create-account:hover .note {
  color: #333333;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account p {
  color: #333;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account .note {
  color: #333;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account .label {
  color: #333;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account .header {
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin: 0px 0px 10px 0px;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account .row .col {
  padding-top: 0px;
  padding-bottom: 0px;
}

.booking-flow .left-container.passenger-details .yellow-header.create-account .default-input {
  border-color: #fff;
}

.booking-flow .left-container.passenger-details .break.space {
  margin: 30px 0 0;
}

.booking-flow .left-container .passenger-inputs {
  padding: 20px 6px 8px;
}

.booking-flow .left-container .passenger-inputs .input-wrapp {
  position: relative;
}

.booking-flow .left-container .passenger-inputs .input-wrapp div.toggle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19px 19px 0 0;
  border-color: #FFD00D transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
}

.booking-flow .left-container .passenger-inputs .input-wrapp div.toggle span {
  font-family: 'Glyphicons Halflings';
  color: #222222;
  position: absolute;
  top: -17px;
  left: 3px;
  font-size: 7px;
}

.booking-flow .left-container .passenger-inputs .input-wrapp div.toggle.error {
  border-color: #ff3131 transparent transparent transparent;
}

.booking-flow .left-container .passenger-inputs .input-wrapp div.toggle.error span {
  color: #fff;
}

.booking-flow .left-container .passenger-inputs .frequent-flyer-inputs {
  background: #f9f9f9;
  padding: 10px 0;
  margin: 0 -5px;
}

.booking-flow .left-container .passenger-inputs .frequent-flyer-inputs .info {
  font-size: 13px;
  color: #aaaaaa;
  padding: 0 15px;
  display: table;
  width: 100%;
}

.booking-flow .left-container .passenger-inputs .checkbox-label {
  cursor: pointer;
  display: table;
  line-height: 0px;
}

.booking-flow .left-container .passenger-inputs .checkbox-label span {
  position: relative;
  top: -2px;
}

.booking-flow .left-container .passenger-inputs .checkbox-label span span {
  font-size: 13px;
  color: #aaaaaa;
  top: 0px;
}

.booking-flow .right-bar .flight-times.inner {
  margin-top: 25px;
  margin-bottom: 25px;
}

.booking-flow .right-bar .flight-times .locations p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0px;
}

.booking-flow .right-bar .flight-times .locations i {
  color: #8f8f8f;
  font-size: 20px;
  margin: 0px 5px;
  position: relative;
  top: 3px;
}

.booking-flow .right-bar .flight-times .times p {
  margin-top: 6px;
  margin-bottom: 0px;
}

.booking-flow .right-bar .price-breakdown table {
  width: 100%;
}

.booking-flow .right-bar .price-breakdown table p {
  margin: 0px;
}

.booking-flow .right-bar .price-breakdown table td {
  vertical-align: top;
}

.booking-flow .right-bar .price-breakdown table td.price p {
  color: #fff;
  text-align: right;
}

.booking-flow .left-container.passenger-details .terms-of-use-label {
  margin: 0 0 21px 0;
}

.booking-flow .left-container.passenger-details .terms-of-use-label a {
  text-decoration: underline;
}

.booking-flow .left-container.passenger-details .terms-of-use-label a:hover {
  /* color: #ffd00d; */
  font-weight: 800;
  text-decoration: unset;
}

.booking-flow .left-container.passenger-details .bootstrap-select .dropdown-menu li a {
  height: 30px;
  padding: 3px 10px;
}

.booking-flow .left-container.passenger-details .bootstrap-select .dropdown-menu li a .img-wrapp,
.booking-flow .left-container.passenger-details .bootstrap-select button .img-wrapp {
  width: 50px;
  display: table;
  float: left;
}

.booking-flow .left-container.passenger-details .bootstrap-select .dropdown-menu li a img,
.booking-flow .left-container.passenger-details .bootstrap-select button .img-wrapp img {
  max-height: 25px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.booking-flow .left-container .passenger-inputs .bootstrap-select.open {
  border-color: #ffd00d;
  border-width: 2px;
}


/* === BOOKING FLOW 4 === */

.booking-flow .left-container.payment-details .header i {
  font-size: 37px;
  top: -17px;
  left: 0px;
}

.booking-flow .left-container.payment-details .card-details .row {
  margin-left: 0px;
  margin-right: 0px;
}

.booking-flow .left-container.payment-details .inner.payment-details-header {
  margin-top: 0px;
  margin-bottom: 0px;
}

.booking-flow .left-container.payment-details .inner.payment-details-header .col.first {
  border-right: 1px dashed #dedede;
}

.booking-flow .left-container.payment-details .inner.payment-details-header .col h3 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.booking-flow .left-container.payment-details .payment-inputs {
  margin-top: 0px;
  margin-bottom: 0px;
}

.booking-flow .left-container.payment-details .card-details {
  border-right: 1px dashed #dedede;
  padding: 0px;
}

.booking-flow .left-container.payment-details .billing-address {
  padding: 0px;
}

.booking-flow .left-container.payment-details .billing-address label {
  font-weight: 400;
  font-size: 14px;
  color: #333;
}

.booking-flow .left-container.payment-details .billing-address label a {
  color: #333;
  text-decoration: underline;
}

.booking-flow .left-container.payment-details .billing-address label a:hover {
  text-decoration: none;
}

.booking-flow .left-container.payment-details .billing-address label input {
  margin: 0px 5px 0px 0px;
}

.booking-flow .right-bar #passenger-details table {
  width: 100%;
}

.booking-flow .right-bar #passenger-details table p {
  margin: 0px;
}

.booking-flow .right-bar #passenger-details table tr.first td {
  padding-bottom: 20px;
}

.booking-flow .right-bar #passenger-details table td.edit {
  vertical-align: middle;
}

.booking-flow .right-bar #passenger-details table td.edit a {
  color: #767676;
  text-align: right;
  font-size: 20px;
}

.booking-flow .right-bar #passenger-details table td.edit a:hover {
  text-decoration: none;
}


/* === BOOKING FLOW 5 === */

.booking-flow .left-container.confirmation-details {
  width: 100%;
}

.booking-flow .left-container.confirmation-details>.inner>.header>h4 {
  margin-left: 55px;
}

.booking-flow .left-container.confirmation-details .header .flaticon-check74 {
  font-size: 35px;
  top: -16px;
  left: 0px;
}

.booking-flow .left-container.confirmation-details .reference-number h3 {
  line-height: 140%;
}

.booking-flow .left-container.confirmation-details .reference-number h4 {
  margin-top: 25px;
}

.booking-flow .left-container.confirmation-details .reference-number .note {
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.booking-flow .left-container.confirmation-details>.inner.flight-info-container>.header>h4 {
  margin-left: 55px;
}

.booking-flow .left-container.confirmation-details .inner.passenger-header h4 {
  margin-left: 55px;
}

/* .booking-flow .left-container.confirmation-details .passenger-details {
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #e0e0e0;
} */

.booking-flow .left-container.confirmation-details .passenger-details .passenger-1 {
  border-right: 1px solid #e0e0e0;
}

.booking-flow .left-container.confirmation-details .passenger-details .col {
  padding: 0px;
}

.booking-flow .left-container.confirmation-details .passenger-details p {
  margin: 0px;
}

.booking-flow .left-container.confirmation-details .passenger-details .heading {
  padding: 20px 30px;
  border-bottom: 1px dashed #e0e0e0;
}

.booking-flow .left-container.confirmation-details .passenger-details .heading p {
  font-size: 16px;
}

.booking-flow .left-container.confirmation-details .passenger-details .heading i {
  color: #b7b7b7;
  font-size: 18px;
}

.booking-flow .left-container.confirmation-details .passenger-details table {
  width: 100%;
}

.booking-flow .left-container.confirmation-details .passenger-details table tr.first th,
.booking-flow .left-container.confirmation-details .passenger-details table tr.first td {
  padding-top: 20px;
}

.booking-flow .left-container.confirmation-details .passenger-details table tr.last th,
.booking-flow .left-container.confirmation-details .passenger-details table tr.last td {
  padding-bottom: 20px;
}

.booking-flow .left-container.confirmation-details .passenger-details table th,
.booking-flow .left-container.confirmation-details .passenger-details table td {
  vertical-align: top;
  padding-bottom: 5px;
}

.booking-flow .left-container.confirmation-details .passenger-details table th {
  padding: 0px 10px 0px 30px;
}

.booking-flow .left-container.confirmation-details .inner.payment-header i.flaticon-credit-card21 {
  font-size: 37px;
  top: -17px;
  left: 0px;
}

.booking-flow .left-container.confirmation-details .inner.payment-header h4 {
  margin-left: 55px;
}

/* .booking-flow .left-container.confirmation-details .passenger-details .personal-details {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #e0e0e0;
} */

.booking-flow .left-container.passenger-details h5 span.bold {
  font-weight: 500;
}

.booking-flow .left-container.passenger-details h5 span.grey {
  color: #a9a9a9;
  font-weight: 100;
}

.booking-flow .left-container.passenger-details input:focus {
  border-width: 2px;
}


/* === FOOTER === */

footer.site-wrapper {
  padding: 60px 20px 20px 20px;
}

footer .footer-logo {
  max-height: 75px;
  width: 42px;
  margin: 0 auto 45px auto;
  display: block;
}

footer .iata-logo {
    display: block;
    margin: -6px 0 0 0;
    float: right;
    height: 40px;
    width: 121px;
}

footer p.copyright {
  color: #999999;
  text-align: left;
  margin: 0px;
}

footer .link-container p {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 700;
  color: #999999;
}

footer .link-container {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 40px;
  margin-bottom: 10px;
}

footer .link-container .col {
  text-align: center;
}

footer ul {
  padding: 0px;
  margin: 0px 0px 10px 0px;
}

footer ul li {
  list-style: none;
  margin: 5px 0px 5px 0px;
}

footer ul li a {
  color: #999999;
  font-size: 17px;
}

footer ul li a:hover {
  text-decoration: none;
  color: #e8c100;
}

footer .social-links {
  width: 100px;
  margin: 0px auto 0 auto;
  height: 60px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

footer .social-links a {
  display: inline-block;
  margin: 0px 10px;
}

footer .social-links a.tw {
  margin-top: 3px;
}

footer .social-links img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: transform .2s;
}
footer .social-links img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  transform: scale(1.2);
}

/* === DEPARTURE BOARD === */

.departure-board-container .board-titles.sticky {
  max-width: 940px;
}

.departure-board-info {
  text-align: center;
  margin: 14px 0 34px 0;
}

.departure-board-info h1 {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
  margin-inline-start: -3px;
  letter-spacing: 0.1px;
}

.back {
  border: 1px solid #ffd00d;
  background-color: white !important;
  color: #ffd00d;
}

.back i {
  color: #ffd00d;
}

.departure-board-info span,.departure-board-info .departure-board-title {
  font-size: 27px;
  font-weight: 400;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.3px;
}

.departure-board-container .yellow-header {
  /* padding: 12px 0; */
  padding-top: 9px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-right: 0px;
  background: rgba(255, 208, 13, 1);
}

.departure-board-container .yellow-header .header-container  .departure button {
  background-color: #fff;
  border-radius: 0;
  height: 43px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  font-weight: 500;
}

.departure-board-container .yellow-header .header-container  .departure .selectpicker-wrapper {
  position: relative;
  float: left;
  margin-top: 2px;
}

.departure-board-container .yellow-header .header-container  .departure .bootstrap-select {
  width: 288px;
}

.departure-board-container .yellow-header .header-container  .departure .selectpicker-icon {
  position: absolute;
  top: 11px;
  left: 7px;
  height: 22px;
  z-index: 10;
}

.departure-board-container .yellow-header .header-container  .departure .dropdown-menu {
  border-radius: 0;
  margin-top: -1px;
}

.departure-board-container .yellow-header .header-container  .col .dropdown-menu>li>a {
  padding-left: 20px;
  font-size: 18px;
}

.departure-board-container .yellow-header .header-container  .col .flights-count span {
  font-weight: 700;
}

.departure-board-container .yellow-header .header-container  .col .flights-count span.no-bold {
  font-weight: 400;
}

/* .departure-board-container .yellow-header .header-container  .col .flights-count a {
  color: #333333 !important;
  text-decoration: none;
  font-weight: 700;
} */

.departure-board-container .yellow-header .header-container  .col .flights-count a:hover {
  text-decoration: underline;
}

.departure-board-container .yellow-header .bootstrap-select.btn-group .dropdown-menu li small {
  color: var(--dark-gray) !important;
  font-size: 18px;
  font-weight: 400;
}

/* .departure-board-container .yellow-header .header-container  .col .filters-trigger p {
  margin: 0px 5px 0 0;
} */

.departure-board-container .date-info {
  background-color: var(--light-gray);
}

.departure-board-container .date-info p {
  margin: 0;
}

.departure-board-container .flight-container .flight-summary {
  background-color: rgba(17, 17, 17, .85);
}

.departure-board-container .board-titles {
  background-color: var(--extra-dark-gray);
}

.departure-board-container .board-titles p {
  float: left;
}

.departure-board-container .board-titles .sort-by {
  float: left;
  width: 10px;
  margin: 9px 0 0 5px;
  cursor: pointer;
}

.departure-board-container .board-titles .arrow-up,
.departure-board-container .board-titles .arrow-down {
  float: left;
  width: 0;
  height: 0;
  border-style: solid;
}

.departure-board-container .board-titles .arrow-up {
  border-width: 0 4px 3px 4px;
  border-color: transparent transparent var(--normal-gray) transparent;
  margin: 2px 0;
}

.departure-board-container .board-titles .arrow-down {
  margin: 1px 0;
  border-width: 3px 4px 0 4px;
  border-color: var(--normal-gray) transparent transparent transparent;
}


.departure-board-container .board-titles .sort-by.active-up,
.departure-board-container .board-titles .sort-by.active-down {
  margin: 11px 0 0 5px;
}

.departure-board-container .board-titles .sort-by.active-up .arrow-up {
  border-color: transparent transparent #fff transparent;
}

.departure-board-container .board-titles .sort-by.active-up .arrow-down {
  display: none;
}

.departure-board-container .board-titles .sort-by.active-down .arrow-down {
  border-color: #fff transparent transparent transparent;
}

.departure-board-container .board-titles .sort-by.active-down .arrow-up {
  display: none;
}

.departure-board-container .board-titles .active-sort-by {
  color: #fff;
  font-weight: 800 !important;
}

.departure-board-container .board-titles .sort-wrapp {
  display: table;
  cursor: pointer;
  float: left;
}

.departure-board-container .board-titles .sort-wrapp:hover .sort-by .arrow-up {
  border-color: transparent transparent #fff transparent;
}

.departure-board-container .board-titles .sort-wrapp:hover .sort-by .arrow-down {
  border-color: #fff transparent transparent transparent;
}

.departure-board-container .board-titles .sort-wrapp:hover p {
  color: #fff;
  cursor: pointer;
}

.departure-board-container .flight-container .flight-summary .row .col.dep-time i {
  color: #ffd00f;
}

.departure-board-container .flight-container .flight-summary .price .per-off {
  color: #ffd00d !important;
  border-style: solid;
  border-width: 1px;
  padding: 2px;
  font-size: 12px;
  margin: 0 0 0 5px;
}

.departure-board-container .flight-container .flight-summary.active .row .col.price .mob-show {
  color: #111111;
}

.departure-board-container .flight-container .flight-summary .light {
  color: #777777;
  font-weight: 100;
}

.departure-board-background {
  background-image: url(/assets/img/backgrounds/home-banner.jpg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  width: 100%;
  height: 1000px;
  position: absolute;
  top: 0;
}

.departure-board-background .cover-wrapp {
  position: relative;
  width: 100%;
  height: 100%;
}

.departure-board-background .cover-wrapp .cover {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 150px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iaGF0MCIgZ3JhZGllbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHgxPSI1MCUiIHkxPSIxMDAlIiB4Mj0iNTAlIiB5Mj0iLTEuNDIxMDg1NDcxNTIwMmUtMTQlIj4KPHN0b3Agb2Zmc2V0PSIzNSUiIHN0b3AtY29sb3I9IiNlZmVmZWYiIHN0b3Atb3BhY2l0eT0iMSIvPgo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZmVmZWYiIHN0b3Atb3BhY2l0eT0iMCIvPgogICA8L2xpbmVhckdyYWRpZW50PgoKPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9InVybCgjaGF0MCkiIC8+Cjwvc3ZnPg==);
  background-image: -moz-linear-gradient(to top, #efefef 34.81%, rgba(240, 240, 240, 0) 100%);
  background-image: -o-linear-gradient(to top, #efefef 34.81%, rgba(240, 240, 240, 0) 100%);
  background-image: -webkit-linear-gradient(to top, #efefef 34.81%, rgba(240, 240, 240, 0) 100%);
  background-image: linear-gradient(to top, #efefef 34.81%, rgba(240, 240, 240, 0) 100%);
}

.departure-board-container .more-flights {
  /* background-color: rgba(17, 17, 17, 0.85); */
  /* color: #ffd00d; */
  background-color: #ffd00d;
  color: black;
  font-family: "Ubuntu";
  font-size: 16px;
  cursor: pointer;
  /* height: 55px; */
  /* height: 62px; */
  height: 42px;
  padding: 5px 18px 0;
  margin: 0 auto;
  display: table;
  margin-top: 21px;
  margin-bottom: 21px;
}

.departure-board-container .more-flights span {
  margin-left: 13px;
  font-weight: 800;
  font-size: 16px;
}

.departure-board-container .more-flights:hover {
  /* background-color: #ffd00d; */
  /* color: #000; */
  background-color: #fcdd53;
}

/* .departure-board-container .more-flights:hover span {
  color: #000;
} */

.tooltip-hover {
  float: left;
  color: #ffd00f;
  border-style: solid;
  border-color: #ffd00f;
  border-width: 1px;
  border-radius: 25px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  margin: 5px 0 0 1px;
  text-decoration: none !important;
  font-weight: 700;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #c7c7c7;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip-inner {
  max-width: 200px;
  padding: 20px;
  color: #000;
  text-align: left;
  border: 1px solid #c7c7c7;
  background-color: #fdfdfd;
  border-radius: 0;
  font-size: 13px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #919191;
}


/* === FILTER AREA === */

.departure-board-container .yellow-header .header-container  .col button.btn-filters {
  /* border-style: solid;
  border-width: 1px; */
  border-color: #7D7D7D;
  border-radius: 0;
  font-weight: 700;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  padding: 2px 5px 0px !important;
  /* margin-top: 1px; */
  position: relative;
}

.departure-board-container .yellow-header .header-container  .col button.btn-filters:hover {
  border-color: #222218;
}

/* .departure-board-container .yellow-header .header-container  .col button.btn-filters span {
  font-family: 'Glyphicons Halflings';
  margin-left: 10px;
  top: 2px;
  position: relative;
} */

.departure-board-container .yellow-header .header-container  .col button.btn-filters span.icon-remove-bar {
  background-color: #ffd00d;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 8px;
  display: none;
}

.departure-board-container .filter-area {
  background-color: #fff;
  padding: 10px 20px 2px;
}

.departure-board-container .filter-area .clear-filters-desktop {
  float: right;
  margin: 0 5px 6px 0;
  display: none;
}

.departure-board-container .filter-area .col {
  padding: 0;
}

.departure-board-container .filter-area .filter-title {
  margin: 7px 0 0 0;
  width: 100%;
  text-align: center;
}

.departure-board-container .filter-area .container-fluid {
  padding-left: 10px;
  padding-right: 10px;
}

.departure-board-container .filter-area .filters-buttons-box .col-lg-4 {
  padding: 0px 5px 8px;
}

.departure-board-container .filter-area .filters-buttons-box .col-lg-4.last-row .filters-box {
  right: 5px;
  left: 5px;
}

.departure-board-container .filter-area button {
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;
  background-color: #f1f1f1;
  border-radius: 0;
  width: 100%;
  color: #222222;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: left;
  height: 37px;
  position: relative;
}

.departure-board-container .filter-area button span.flaticon-agree {
  font-weight: 700;
  position: absolute;
  top: 7px;
  right: 9px;
}

.departure-board-container .filter-area button span.toggle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19px 19px 0 0;
  border-color: #FFD00D transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.departure-board-container .filter-area button span.glyphicon {
  font-family: 'Glyphicons Halflings';
  color: #222222;
  position: absolute;
  top: -17px;
  left: 3px;
  font-size: 7px;
}

.departure-board-container .filter-area .clear-fliters-desktop {
  float: right;
  margin: 0 5px 5px;
}

.departure-board-container .filter-area button.clear-filters.active {
  margin-bottom: 0;
  display: none;
  float: left;
}

.departure-board-container .filter-area .selections p {
  float: left;
  margin-top: 10px;
}

.departure-board-container .filter-area .selections ul {
  list-style-type: none;
  padding: 0;
}

.departure-board-container .filter-area .selections ul li {
  float: left;
  margin: 0 23px 10px 0;
}

.departure-board-container .filter-area .selections ul li .glyphicon {
  font-family: 'Glyphicons Halflings';
}

.departure-board-container .filter-area .selections ul li img {
  width: 10px;
  position: absolute;
  top: 9px;
  right: 8px;
}

.departure-board-container .filter-area .selections ul li a {
  display: table;
  padding: 3px 8px;
  min-width: 130px;
  border-style: solid;
  border-width: 1px;
  border-color: #a0a0a0;
  position: relative;
  text-decoration: none !important;
}

.departure-board-container .filter-area .selections ul li a:hover {
  background-color: #F1F1F1;
}

.departure-board-container .filter-area .clear-filters-box {
  margin-top: 10px;
}

.departure-board-container .filter-area .clear-filters-box a {
  float: right;
}

.departure-board-container .filter-area .clear-filters-box img {
  float: right;
  width: 14px;
  cursor: pointer;
  margin: 8px 0 0 15px;
}

.departure-board-container .filter-area .clear-filters-box a span {
  display: table;
  padding: 3px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #FFD00D;
  background-color: #FFD00D;
  float: left;
}

.departure-board-container .filter-area .clear-filters-box a span:hover {
  background-color: #fcdd53;
  border-color: #fcdd53;
}

.filters-box {
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;
  position: absolute;
  top: 36px;
  right: 5px;
  left: 5px;
  background-color: #fff;
  z-index: 999;
  display: none;
}

.filters-box.no-buttons {
  border-top: none;
}

.filters-box .filters-box-nav {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #cfcfcf;
  margin: 0 0 17px 0;
  position: relative;
}

.filters-box .filters-box-nav button {
  width: 50%;
  float: left;
  background: none;
  border-width: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  height: 27px;
  color: #676767;
}

.filters-box .filters-box-nav button.select-all {
  border-width: 0 1px 0 0;
}

.filters-box .filters-box-nav button.disable {
  color: #DDD;
}

.filters-box ul {
  list-style-type: none;
  padding: 0 10px;
  padding-bottom: 21px;
}

.filters-box ul li {
  position: relative;
  padding-bottom: 10px;
}

.filters-box ul li label {
  cursor: pointer;
  padding-left: 25px;
  position: relative;
  color: #676767;
}

.filters-box ul li span.price {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #a7a7a7;
}

.filters-box input[type="checkbox"] {
  display: none;
}

.filters-box input[type="checkbox"]+label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  /* background: url(/assets/img/checkbox-a.png) left top no-repeat; */
  position: absolute;
  top: 2px;
  left: 0;
}

.filters-box input[type="checkbox"]:checked+label span {
  background: url(/assets/img/checkbox-b.png) left top no-repeat;
}

.filters-box input[type="submit"] {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  webkit-border-radius: 0;
  font-family: "Ubuntu";
  font-size: 16px;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  display: table;
  border-radius: 0;
  background-color: #dddddd;
}

.filters-box input[type="submit"]:hover {
  background-color: #cfcfcf;
}

.filters-box input[type="submit"].active {
  background-color: #ffd010;
}

.filters-box input[type="submit"].active:hover {
  background: #fcdd53 !important;
}

/* .filters-box .price-from {
  width: 100%;
  display: table;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #a7a7a7;
} */

.filters-box .submit-wrapp {
  padding: 0 10px 10px;
}

.filters-box .filters-box-scroll {
  max-height: 100%;
  width: 100%;
}
.filters-box-scroll.no-search {
  padding-top: 17px;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e4e4e4 !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #777777 !important;
  width: 8px!important;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px!important;
}

.mCSB_container_wrapper>.mCSB_container {
  padding: 0 !important;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 10px !important;
}

.departure-board-container .flight-container .flight-summary span.mob-remove,
.departure-board-container .flight-container .flight-summary span.mob-show {
  color: #fff;
}

.departure-board-container .flight-container .flight-summary span.was-price {
  font-weight: 100;
}

.departure-board-container .filter-area .filters-box .filters-box-search-wrapp {
  position: relative;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #cfcfcf;
}

.departure-board-container .filter-area .filters-box .filters-box-search {
  border: none;
  outline: none;
  -webkit-appearance: none;
  webkit-border-radius: 0;
  height: 27px;
  font-family: "Ubuntu";
  font-size: 12px;
  width: 100%;
  padding: 0 11px 0 32px;
}

.departure-board-container .filter-area .filters-box .filters-box-search-icon {
  position: absolute;
  top: 7px;
  left: 11px;
  height: 13px;
}

.departure-board-container .filter-area .filters-box .clear-search {
  font-family: 'Glyphicons Halflings';
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 11px;
  color: #A9A9A9;
  display: none;
}

.departure-board-container .showing {
  background-color: rgba(17, 17, 17, .65);
  padding-bottom: 3px;
}

.departure-board-container .showing p {
  color: #b2b2b2;
  text-align: center;
  margin: 0;
}


/* === GLOBAL FLIGHT DETAILS === */

.global-flight-details-header {
  position: relative;
  margin: 10px 0 7px 0;
}

.global-flight-details-header.space-top {
  margin-top: 30px;
}

.global-flight-details-header.second {
  margin-top: 30px !important;
}

.global-flight-details-header h4 {
  margin: 0 0 10px 5px;
  padding: 0 0 0 50px;
  font-size: 17px;
}

.global-flight-details-header h4 i {
  position: relative;
  top: 2px;
  margin: 0 -5px;
}

.global-flight-details-header img {
  position: absolute;
  top: -4px;
  width: 32px;
}

.global-flight-details-wrapp .global-flight-details-header img {
  top: 2px;
}

.global-flight-details-header .time {
  position: absolute;
  top: 0;
  right: 0;
}

.global-flight-details-header .time img {
  float: left;
  top: 4px;
  right: 5px;
  position: relative;
  height: 14px;
  width: auto !important;
}

.global-flight-details-header .time span {
  float: left;
  color: #000;
  margin: 0 5px 0 0;
  font-weight: 300;
}

.global-flight-details {
  border-style: solid;
  border-width: 1px;
  border-color: var(--light-gray);
}

.global-flight-details .flight {
  width: 100%;
  display: table;
}

.global-flight-details .flight.top.no {
  margin-bottom: 0;
}

.global-flight-details .flight.bottom {
  margin-top: 10px;
}

.global-flight-details .graph .col {
  height: 40px;
}

.global-flight-details .stop {
  width: 100%;
  display: table;
  border-style: dashed;
  border-width: 1px 0;
  border-color: #e0e0e0;
}

.global-flight-details .stop .additional p {
  float: right;
}

.global-flight-details .stop .additional img.clock-img {
  height: 15px;
  position: relative;
  margin-right: 8px;
  width: auto;
  top: auto;
}

.global-flight-details .stop span.hide-mobile {
  float: none;
  top: auto;
  margin: 0;
}

.global-flight-details .icon {
  padding-right: 0;
  padding-left: 65px;
  top:-6px;
}

.global-flight-details .icon .wrapp {
  position: relative;
  top: 8px;
  margin: 0 auto;
  display: table;
}

.global-flight-details .icon .wrapp img {
  width: 30px;
}

.global-flight-details .additional {
  padding: 0;
}

.global-flight-choose .global-flight-details .additional {
  width: 41.116667%;
}

.global-flight-details .additional img {
  width: 22px;
  float: left;
  position: relative;
  top: 8px;
}

.global-flight-details .additional span {
  font-size: 13px;
  margin: 0 0 0 10px;
  position: relative;
  top: 13px;
  line-height: 14px;
  float: left;
}

.global-flight-details .additional span span {
  margin: 0;
  top: 5px;
  font-size: 10px;
  line-height: 0;
  position: relative !important;
}

.global-flight-details .left-text {
  text-align: right;
}

.global-flight-details .left-text,
.global-flight-details .right-text {
  padding: 0;
}

.global-flight-details .left-text p,
.global-flight-details .right-text p {
  margin: 0;
  position: relative;
  top: 13px;
  line-height: 14px;
}

.left-text-p-top , .right-text-p-top {
  top: 0px !important;
}


.global-flight-details .left-text p {
  color: #a8a8a8;
  font-weight: 100;
}

.global-flight-details .left-text p span {
  color: black;
  font-weight: 400;
}

.global-flight-details .left-text p span:not(.strong) {
  margin-left: 2px;
}

.global-flight-details .left-text p span.mobile {
  display: none;
}

.global-flight-details .left-text p span.strong {
  font-weight: 700;
}

.global-flight-details .right-text p {
  font-size: 15px;
}

.global-flight-details .right-text img.clock-img {
  height: 15px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.global-flight-details .right-text p strong.different {
  background-color: #ffe67d;
}

.global-flight-details .timeline .wrapp {
  margin: 0 auto;
  display: table;
  position: relative;
  top: 14px;
}

.global-flight-details .timeline .dot {
  border-style: solid;
  border-width: 3px;
  border-color: #ffd00d;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 40px;
}

.dot_top img {
  width: 17px;
  margin-left: 1px;
  margin-top: -4px;
}

.dot_bottom img {
  width: 17px;
  margin-left: 1px;
  margin-bottom: 6px;
}



/* .dot_bottom {
  margin-top: -2px;
} */

.dot_top {
  margin-top: -12px;
}

.global-flight-details .timeline .dot.full {
  background-color: #ffd00d;
}

.global-flight-details .timeline .wrapp.clock {
  top: 8px;
}

.global-flight-details .timeline .wrapp.clock img {
  height: 15px;
  background-color: #fff;
}

.global-flight-details .left-clock {
  padding: 0;
}

.global-flight-details .left-clock div {
  font-size: 13px;
  margin: 0;
  position: relative;
  top: 13px;
  line-height: 14px;
  float: right;
  width: 62.55px;
  display: flex;
  justify-content: space-around;
}

.global-flight-details .left-clock img.clock-img {
  height: 14px;
  position: relative;
}

.global-flight-details .timeline .line {
  width: 3px;
  height: 90px;
  background-color: #ffd00d;
}

.global-flight-details .timeline .line.wait {
  background-color: #efefef;
  height: 150px;
}

.global-flight-details .timeline .line.wait.no {
  display: none;
}

.global-flight-details .details {
  padding: 0;
}

.global-flight-details .details img {
  max-width: 45px;
}

.global-flight-details .details ul.info {
  list-style-type: none;
  color: #696969;
  margin: 0;
  font-weight: 100;
}

/* .global-flight-details .details ul.info strong {
  color: #333;
} */

/* .global-flight-details .details ul.info span {
  color: #ffcf03;
} */

.global-flight-details .details ul .info-small {
  font-size: 10px;
  color: #a2a2a2;
  margin: 3px 0 0 0;
}

.global-flight-details .details ul .info-small-for-desktop span {
  font-size: 22px;
  line-height: 0px;
  color: --var(--dark-gray);
  position: relative;
  top: 0px;
}

.global-flight-details .flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.global-flight-details p {
  line-height: 18px;
}

.global-flight-details .color1 {
  color: #696969;
  font-weight: 100;
}

.global-flight-details .color1.small {
  font-size: 13px;
}

.global-flight-details .nowrap {
  white-space: nowrap;
}

.global-flight-details-nav {
  margin: 5px 0 0 0;
  display: table;
  width: 100%;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #d1d1d1;
  padding: 0 0 10px;
}

.departure-board-container .global-flight-details-nav {
  border-width: 0;
  padding: 0;
}

.global-flight-details-nav .yellow-button {
  margin: 0 0 0 5px;
}

.global-flight-details-nav .yellow-button,
.global-flight-details-nav .global-person-choose {
  float: right;
}


.global-flight-details .flight .show-phone {
  display: none;
}

/* === GLOBAL FLIGHT CHOOSE== */

.global-flight-choose {
  margin: 50px 0 0 0;
}

.global-flight-choose .header h4 {
  font-size: 18px;
  font-weight: 500;
  border-style: dashed;
  border-width: 0 0 1px 0;
  border-color: #e0e0e0;
  padding: 0 0 7px 0;
}

.global-flight-choose .header .details {
  color: #6f6f6f;
  font-weight: 600;
  font-size: 16px;
  margin-top: -8px;
}

.global-flight-choose .header .details .time {
  float: left;
}

.global-flight-choose .header .details .number {
  float: right;
  font-weight: 400;
}

.global-flight-choose .header .details .date {
  font-weight: 400;
}

.global-flight-choose .header .details .date span {
  font-weight: 600;
  color: #ffd00d;
}

.global-flight-choose .entry {
  display: table;
  width: 100%;
}

.global-flight-choose .labels {
  background-color: #f0f0f0;
  padding: 0 20px;
}

.global-flight-choose .labels .col {
  padding: 0 3px;
  height: 43px;
}

.global-flight-choose .labels p {
  margin: 16px 0 0 0;
  line-height: 12px;
  font-weight: 600;
}

.global-flight-choose .labels .stops {
  text-align: center;
}

.global-flight-choose .information {
  position: relative;
  padding: 0 20px;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #d1d1d1;
}

.global-flight-choose .information .rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.global-flight-choose .information .col {
  padding: 0 3px;
  height: 60px;
}

.global-flight-choose .information p {
  margin: 21px 0 0 0;
  line-height: 16px;
}

.global-flight-choose .information .price p {
  font-weight: 700;
}

.global-flight-choose .information .price p span {
  font-weight: 400;
}

.global-flight-choose .information .color-1 {
  color: #bbbbbb;
}

.global-flight-choose .information .color-2 {
  color: #bbbbbb;
}

.global-flight-choose .information .color-3 {
  font-weight: 700 !important;
  background-color: #b5e4fc;
}

.global-flight-choose .information .departure .color-1,
.global-flight-choose .information .arrival .color-1 {
  width: 29px;
  margin-right: 1px;
  float: left;
}

.global-flight-choose .information.outbound p {
  margin: 14px 0 0 0;
}

.global-flight-choose .information.outbound .price p {
  margin: 21px 0 0 0;
}

.global-flight-choose .information:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.global-flight-choose .information .icon img {
  width: 20px;
  position: absolute;
  top: 19px;
}

.global-flight-choose .information .icon.dual img.departure {
  top: 14px;
}

.global-flight-choose .information .icon.dual img.return {
  top: 30px;
}

.global-flight-choose .information .stops {
  text-align: start;
}

.global-flight-choose .information .arrow {
  padding: 0;
}

.global-flight-choose .information .arrow i {
  color: #ffd00d;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  top: 14px;
  right: 0;
}

.global-flight-choose .information .different {
  background-color: #ffe67d;
}

.global-flight-choose .information.active {
  background-color: #fafafa !important;
  border-color: #fff;
}

.global-flight-choose .information.active .different {
  background-color: #ffe67d;
}

.global-flight-choose .global-flight-details-header {
  margin: 10px 0 7px;
}

.global-flight-details-wrapp .global-info-wrapp {
  /* margin: 5px 0 0 0; */
}


/* === GLOBAL FLIGHT CHOOSE == */

.flight-details-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  padding: 20px;
  overflow-y: hidden;
  display: none;
}

.flight-details-box .flight-details-box-wrapp {
  max-width: 850px;
  margin: 0 auto;
}

.flight-details-box .flight-details-box-content {
  overflow-x: hidden;
  padding:0px 0px 20px 0px;
  background-color: #fff;
  overflow-y: scroll;
  max-height: calc(100vh - 145px);
}

.flight-details-box .flight-details-box-content.price-breakdown {
  background-color: #fff;
  overflow-y: visible;
}

.flight-details-box .box-title {
  color: #fff;
}

.flight-details-box .box-title span {
  font-weight: 400;
  color: #cecece;
}

.flight-details-box .box-title i {
  /* top: 4px; */
  position: relative;
}

.flight-details-box .close-box {
  font-family: 'Source Sans Pro', sans-serif;
  border: none;
  background: #ffd00d;
  font-size: 13px;
  padding: 7px;
  float: right;
  margin-top: 10px;
}

.flight-details-box .close-box:hover {
  color: #ffd00d;
  background-color: #000;
}

.highlight {
  background-color: #cfcfff6e;
}

/* === GLOBAL PRICE BREAKDOWN == */

.flight-details-box .flight-details-box-content.price-breakdown {
  padding: 20px 20px 11px;
}

.price-breakdown .row {
  margin-left: -20px;
  margin-right: -20px;
}

.price-breakdown h5 {
  /* font-size: 15px; */
  font-weight: 500;
  text-align: center;
  margin-top: 0;
}

.price-breakdown h5 span {
  color: var(--dark-gray);
  font-weight: 400;
}

.price-breakdown p {
  color: black;
  text-align: center;
  margin-bottom: 0;
}

.price-breakdown .subtotal h5,
.price-breakdown .subtotal p {
  text-align: right;
}

.price-breakdown .type,
/* .price-breakdown .services, */
/* .price-breakdown .total  */
.price-breakdown .number {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #e2e2e2;
}

.price-breakdown .total {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: #e2e2e2;
}

.price-breakdown .total-cost {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #e2e2e2;
  margin-top: 10px;
  padding-top: 10px;
}

.price-breakdown .cost p {
  color: #333;
  text-align: right;
  font-weight: 800;
  /* font-size: 17px; */
}

/* .price-breakdown .cost p span {
  margin-left: 5px;
} */

.price-breakdown .info p {
  color: #a5a5a5;
  font-size: 12px;
  text-align: left;
  margin-top: 6px;
}

.flight-box-enabled {
  overflow-y: hidden;
}

#show-breakdown {
  cursor: pointer;
}

#show-breakdown:hover {
  color: #ffd00d;
}


/* === GLOBAL INFO WRAPP === */

.global-info-wrapp {
  display: table;
  width: 100%;
  text-align: center;
  /* margin: 10px 0; */
  cursor: pointer;
  display: none;
}

.global-info-wrapp p {
  color: #333;
  margin: 0;
}

.global-info-wrapp p .icon {
  display: inline-block;
  color: #ffd00f;
  border-style: solid;
  border-color: #ffd00f;
  border-width: 2px;
  border-radius: 25px;
  width: 20px;
  min-width: 20px !important;
  height: 20px;
  font-size: 13px;
  text-align: center;
  margin: 0px 5px 0 0;
  font-weight: 700;
  line-height: 16px;
  position: relative;
  top: 0px;
}

.global-info-wrapp.yellow {
  border: 1px #ffd00d solid;
}


/* === FIREFOX FIX === */

@-moz-document url-prefix() {
  .departure-board-container .filter-area .selections ul li {
    float: left;
    margin: 0 5px 10px 0;
  }
}
