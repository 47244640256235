@media  (max-width: 1220px) {
	.site-container{padding:0px 20px;}
}

@media  (max-width: 1200px) {
	/* === CARDS === */
	.card .inner{margin:15px;}
	.card .inner h3 a{font-size:17px;}

	/* === GLOBAL FLIGHT DETAILS === */
	/* .global-flight-choose .global-flight-details .graph .col.left-text {width:22%;} */
	/* .global-flight-choose .global-flight-details .graph .col.timeline {width:11.33333333%;} */
	/* .global-flight-choose .global-flight-details .graph .col.additional {width:38.666667%;} */
}

@media  (max-width: 1160px) {
	.mob-remove7 {display:none;}
}

@media  (min-width: 1080px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col button.btn-filters { padding: 5px 0px 10px !important; margin-left:5px;}
	.departure-board-container .yellow-header .header-container  .col button.btn-filters span {margin-left:5px;}
}

@media  (max-width: 1080px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col.departure p, .departure-board-container .yellow-header .header-container  .col p {font-size:15px; margin-top:0px !important;}
	.departure-board-container .yellow-header .header-container  .departure button, .departure-board-container .yellow-header .header-container  .col .dropdown-menu > li > a, .departure-board-container .yellow-header .header-container  .col button.btn-filters {font-size:15px;}
	.departure-board-container .yellow-header .bootstrap-select.btn-group .dropdown-menu li small {font-size:15px;}
	.departure-board-container .yellow-header .header-container  .col button .flaticon-agree {font-size:13px;}
	
	
	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-choose  .col.price {width:13.5%;}
	.global-flight-choose  .col.arrival {width:15.666667%;}
} 

@media  (max-width: 1130px) {
	/* === BOOKING FLOW === */
	.booking-flow .left-container{width:100%;}
	.booking-flow .right-bar .content {display:none;}
	.booking-flow .right-bar .persons {display:none;}
	.booking-flow .go-to-calendar {bottom: auto; top:-43px; padding:12px 10px; font-size:15px; background-color:#ffd00d !important;}
	.booking-flow .go-to-calendar i {font-size:16px; top:0;}
	.booking-flow .go-to-calendar span {top:-2px;}
	
	.booking-flow .right-bar {width:100%; margin:0 !Important; position:fixed; top:auto!Important; bottom:0; left:0; padding:15px 10px; cursor:pointer; z-index:999; min-height: 54px;}
	.booking-flow .right-bar .main-header {border-width:0; padding:0; margin:0;}
	.booking-flow .right-bar .main-header .icon.basket {width:16px; top:19px;}
	.booking-flow .right-bar .main-header h4 {font-size:15px; margin:2px 0 0 26px; font-family:'Source Sans Pro', sans-serif;}
	.booking-flow .right-bar .main-header h4 span.mobile-hide {display:none;}
	.booking-flow .right-bar .main-header h4 span.mobile-on {display:inline;}
	
	.booking-flow .right-bar .title {font-size:14px; margin:15px 0 0px; padding:5px 0 0 0; border-style:solid; border-width:1px 0 0 0; border-color:#848484;}
	.booking-flow .right-bar .title i {top:2px;}
	
	.booking-flow .right-bar ul.basket {margin-top:10px;}
	.booking-flow .right-bar .basket li ul.details {font-size:12px;}
	.booking-flow .right-bar .basket li.entry {margin:0;}
	.booking-flow .right-bar .basket li.entry:nth-child(2)  {margin-top:10px;}
	.booking-flow .right-bar .basket li .col-lg-3 {max-width:30px;}
	.booking-flow .right-bar .basket li#current, .booking-flow .right-bar .basket li#outbound {margin-bottom:5px;}
	.booking-flow .right-bar .basket li ul.details li:first-child {margin-bottom:-9px;}
	.booking-flow .right-bar .nav-buttons button {font-size: 12px; padding:3px 0;}
	.booking-flow .right-bar .costs {padding:4px 0 0 0; margin-bottom:-12px;}
	.booking-flow .right-bar .costs p, .booking-flow .right-bar .costs p span:not(.price-decimals) {font-size:14px;}
	.booking-flow .right-bar .basket li img.icon {width:20px; top:0;}

	.booking-flow .right-bar .nav-buttons {margin:8px 0 -4px 0;}
	
	.booking-flow .right-bar .price-mobile {display:inline;}
	.booking-flow .right-bar .persons {font-size:14px; margin:0;}
	
	.booking-flow .go-to-calendar { width:100%; right:0; font-family:'Source Sans Pro', sans-serif;}
	
	/* === GLOBAL FLIGHT DETAILS === */
	/* .global-flight-choose .global-flight-details .graph .col.left-text {width:25%;} */
	/* .global-flight-choose .global-flight-details .graph .col.timeline {width:8.33333333%;} */
	/* .global-flight-choose .global-flight-details .graph .col.additional {width:41.666667%;} */

	.global-flight-choose  .col.price {width:12.5%;}
	.global-flight-choose  .col.arrival {width:13.666667%;}
	
	footer.site-wrapper {background-color:#efefef;}
	
	.go-back-calendar {margin:15px 0 0 0;}
} 

@media  (min-width: 1010px) {
	/* === DEPARTURE BOARD === */
	/* .departure-board-container .container-fluid {padding-left:20px; padding-right:20px;} */
	.departure-board-container .yellow-header .header-container  .col.departure i {left:-9px;}
	
	.departure-board-container .flight-container .flight-summary {height:61px;}
	
	.departure-board-container .board-titles  .tooltip-hover {margin:5px 0 0 3px;}
	.departure-board-container .flight-container .flight-summary p {margin:17px 0 0 0;}
	
	.departure-board-container .flight-container .flight-summary .row .col.dep-time i {margin-top:19px;}
	.n-header-grid-wrapper .n-header-grid { align-items: center;}
}

@media  (max-width: 1010px) {
	.global-flight-choose .labels .col {height:35px;}
	.global-flight-choose .labels p {margin:11px 0 0 0;}
	.booking-flow .left-container .main-header, .global-flight-choose .information .col {height: 54px;} 
	.global-flight-choose .information.outbound p {margin-top: 13px;}
	.global-flight-choose .information.outbound .price p {margin-top: 21px;}
	.global-flight-choose .information .icon img.pil_1 { top: 9px !important}
	.global-flight-choose .information .icon img.return { top: 31px !important}
	/* .departure-board-container .more-flights {height: 54px; padding-top:11px} */
}

@media  (min-width: 992px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .filter-area .filters-buttons-box .col-lg-4 {width: 20.00%;}
}

@media  (max-width: 992px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container{margin-bottom:20px;}
	.departure-board-container .yellow-header .header-container  .col.location{border:none; padding-top:13px; padding-bottom:0px;}
	
	.departure-board-container .yellow-header .header-container  .col.date{padding-top:0px;}
	.departure-board-container .yellow-header .header-container  .col.date{border:none;}
	.departure-board-container .yellow-header .header-container  .col.date .date-time{display: none;}

	.departure-board-container .flight-container .flight-details-container .right-container.passengers{ margin-top:10px;}

	.departure-board-container .flight-container .flight-details-container .close-panel{bottom:-15px; font-size: 13px; right:5px;}
	.departure-board-container .flight-container .flight-details-container .close-panel i{font-size:16px; margin-left: 3px; top:1px;}
	
	.departure-board-container .filter-area .filters-buttons-box .col-sm-12 {margin-top:2px;}
	.departure-board-container .filter-area .col-lg-4.fix-row .filters-box {right:0;}
	.departure-board-container .filter-area .selections p {display:none;}
	
	.departure-board-container .yellow-header .header-container  .departure .bootstrap-select {width:auto;}
	.departure-board-container .yellow-header .header-container  .col.departure i {display:none;}
	
	.departure-board-container .yellow-header .header-container  .col.departure p {margin: 6px 10px 0 0px;}
	
	#filters-container{max-width:800px; width:100%;}
	

	/* === CARDS === */
	.card{width:calc((100% - 40px)/3);  margin:0px 20px 20px 0px;}

	/* === FULL WIDTH IMG CARD === */
	.img-card{height:340px;}

	/* === WHERE WE FLY PAGE === */
	.banner-wrapper.where-we-fly{height:500px;}

	/* === MY ACCOUNT === */
	.my-account .personal-details .personal-info{border-right:none; border-bottom:1px solid #ececec;}
	.my-account .personal-details th, .my-account .personal-details td{width:50%;}

	/* === EDITORIAL PAGE === */
	.editorial-page .left-container{width:100%; }
	.editorial-page .right-bar{width:100%; }
	.editorial-page .left-container .inner{margin-bottom:0px;}

	/* === MY ACCOUNT === */
	.my-bookings .tickets{display: none;}
	
	/* === DEPARTURE BOARD === */
	.departure-board-container .bootstrap-select.btn-group .dropdown-menu {width:100%; min-width:170px;}
}

@media  (max-width: 985px) {
	/* .departure-board-container .yellow-header .header-container  .col.departure {width:50%;} */
	/* .departure-board-container .yellow-header .header-container  .col.date {width:50%;} */
	
} 

@media(max-width:850px) {
	.dot_top img , .dot_bottom img {
	  width: 15px;
	  margin-top: -3px;
	}
  
	.dot_bottom img {
	  margin-left: 2px;
	}
  
	/* .global-flight-details .timeline .dot {
	  width: 24px;
	  height: 24px;
	} */
  
	.dot_top {
	  margin-top: -12px !important;
	}
  }
  
  @media(max-width:500px) {
	.dot_top img , .dot_bottom img {
	  width: 12px;
	  margin-top: -3px;
	}
  
	.dot_top img {
	  margin-top: -9px !important;
	  margin-left: 2px !important; 
	}
  
	.dot_bottom img {
	  margin-top: -11px !important;
	  margin-left: 1px !important; 
	}
  
	.global-flight-details .timeline .dot {
	  width: 17px;
	  height: 17px;
	}
  
  }

@media  (max-width: 950px) {
	/* === CARDS === */ 
	/* .card, .img-card { background: none !important;} */
	.card .img-wrap {background: none;}
 	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-details .icon {width:14.666667%;}
	.global-flight-details .additional {width:39.666667%}	
	.global-flight-details .timeline {width:12.333333%;}
	/* .global-flight-details .left-clock {width:38.666667%;} */
	/* .global-flight-details  .right-text {width:48%;} */

	/* .global-flight-choose .global-flight-details .graph .col.left-text {width:24%;} */
	/* .global-flight-choose .global-flight-details .graph .col.timeline {width:12.33333333%;} */
	/* .global-flight-choose .global-flight-details .graph .col.additional {width:38.666667%;} */
	/* .global-flight-choose .global-flight-details .graph .col.right-text {width:48%;} */
	.global-flight-choose .global-flight-details .graph .col.right-text {width:59%;}
}

@media  (max-width: 930px) {
	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-details .icon {padding-left:15px;}
	.global-flight-details .details ul.info {font-size:13.5px;}
}


@media  (max-width: 880px) {
	/* === GLOBAL FLIGHT DETAILS === */
	.price-breakdown h5 {font-size:15px;}
	.price-breakdown .col {padding:0 5px;}
}

@media  (max-width: 835px) {
	/* === BOOKING FLOW === */
	.booking-flow .tab-header .tabs li {height:auto;}
	.booking-flow .tab-header .tabs li a{padding:9px 2px;}
	.booking-flow .tab-header .tabs li a i{display: block;}
	.booking-flow .tab-header .tabs li a span{font-size:13px; margin-left:0px;}
}

@media  (max-width: 825px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-details-container .left-container .icon i{display: none;}
	.departure-board-container .flight-container .flight-details-container .left-container .flight-details .details{width:100%;}

	/* === EDITORIAL PAGE === */
	.editorial-page .left-container .flight-info-container .icon i{display: none;}
	.editorial-page .left-container .flight-info-container .flight-details .details{width:100%;}
}

@media  (max-width: 800px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .mob-remove{display:none;}
	.departure-board-container .yellow-header .mob-show {display:inline-block;}
	
	/* === NAVIGATION === */
	nav{display: none;}
	.mobile-menu{display: block;}
	
	/* === GLOBAL FLIGHT DETAILS ===*/
	.global-flight-details .left-text p span.desktop {display:none;}
	.global-flight-details .left-text p span.mobile {display:inline;}
	.global-flight-details .left-text p {position:relative; top:13px; font-size:13px; color:#a8a8a8; line-height:14px; font-weight:100;}
	.global-flight-details .left-text p span {font-size:15px; color:#333333; font-weight:400;}
	
	.global-flight-details-header h4 {margin:0 0 8px 0;}
	
	/* === COOKIES INFO === */
	.cookies-info .col {width:100%;}
	.cookies-info .col p {margin-bottom:0;}
	.cookies-info .col span.close-cookies {margin:7px 0 0;}
	
	.departure-board-container .flight-container .flight-summary, .departure-board-container .flight-container .flight-summary:hover {background-color: rgba(17, 17, 17, 0.90) !important;}
}

@media  (min-width: 767px) {
	.departure-board-container .filter-rotate { -webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); -o-transform: rotate(180deg); -ms-transform: rotate(180deg); transform: rotate(180deg);}
	.departure-board-container .yellow-header .header-container  .col button.btn-filters span.filter-rotate {left:1px;}
}

@media  (max-width: 767px) {
	.mob-remove {display:none;}
	.mob-show {display:inline-block;}
	
	header.site-wrapper {padding:5px 0px;}

	/* === HEADER === */
	nav ul li{margin:0px 0px 0px 10px;}
	nav ul li:first-of-type{margin-left:0px;}
	nav ul li a{padding-left:25px;}
	nav ul li a i{font-size: 18px; top:-9px;}

	/* === DEPARTURE BOARD === */
	.departure-board-info h1 {
		font-size: 36px;
	}
	.departure-board-info span {
		font-size: 22.7px;
	}
	.departure-board-background {height:640px;}
	.departure-board-container .flight-container .flight-summary .row .col.dep-time i { font-size:12px; margin-top:18px; }
	.departure-board-container .filter-area .filters-buttons-box .col-lg-4.last-row {margin-bottom:7px;}
	.departure-board-container .filter-area .filters-buttons-box .col-lg-4 {padding:0;}
	.departure-board-container .filter-area .filters-box {right:0 !important; left:0 !important; position:relative !important; top:-2px;}
	
	.filters-box .filters-box-nav button, .departure-board-container .filter-area .filters-box .filters-box-search {height:37px;}
	.departure-board-container .filter-area .filters-box .filters-box-search-icon {top:12px;}
	.filters-box input[type="submit"] {padding:5px 10px; margin-top:5px;}

	.departure-board-container .yellow-header .header-container  .col button .flaticon-agree {padding-left: 3px;}
	
	.departure-board-container .flight-container .flight-summary .row .col.destination span.time {margin-right:25px;}
	.departure-board-container .yellow-header{ padding: 8px 0; padding-right: 0px; padding-left: 0px; padding-top: 6px; padding-bottom: 6px; }
	.departure-board-container .yellow-header .header-container  .col.departure p.mob-view{display: block;}
	.departure-board-container .yellow-header .header-container  .col.location, .departure-board-container .yellow-header .header-container  .col.time{border:none; padding:12px 7px 0px 7px;}
	.departure-board-container .yellow-header .header-container  .col.departure i{display: none;}
	.departure-board-container .yellow-header .header-container  .col.departure p{margin-left:0px; text-align: left;}
	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {margin-left: 59px}

	.departure-board-container .flight-container .flight-details-container {padding-right:17px; padding-left:17px;}
	.departure-board-container .flight-container .flight-details-container .container .outer-border .inner{margin:20px;}
	.departure-board-container .flight-container .flight-summary:hover{background-color:rgba(17, 17, 17, 0.95);}
	.departure-board-container .number-of-flghts-container{padding:3px 15px;}
	.departure-board-container .load-more-container i{margin-left: 10px; font-size: 19px;}
	
	.departure-board-container .filter-area .filters-buttons-box .col-xs-24 {margin-top:9px;}
	.departure-board-container .filter-area .clear-filters-box {margin:0 0 10px 0;}
	.departure-board-container .filter-area .filters-buttons-box {margin-top:-9px;}
	.departure-board-container .filter-area {padding: 10px 15px 2px;}
	
	/* === GLOBAL FLIGHT DETAILS === */


	.global-flight-choose .col.price {width:13.5%;}
	.global-flight-choose  .col.stops {width:11.5%;}

	/* === FILTERS === */
	#filters-container .inner .row.first{padding-bottom:0px;}
	#filters-container .inner .row .col{margin-bottom:30px;}

	/* === FULL WIDTH IMG CARD === */
	.img-card{height:300px;}

	/* === WHERE WE FLY PAGE === */
	.banner-wrapper.where-we-fly{height:400px;}
	.slider-control-nav{top:-30px;}
	.banner-contents .where-we-fly-selection .col.first, .banner-contents .where-we-fly-selection .col.last{padding:0px;}

	/* === MY ACCOUNT === */
	.my-account .personal-details .heading{padding:20px;}
	.my-account .personal-details table th{padding:0px 10px 0px 20px;}
	.my-bookings td.first, .my-bookings th.first{padding-left:20px;}
	.my-bookings td.last, .my-bookings th.last{padding-right:20px;}
	.my-bookings .yellow-button, .my-alerts td .yellow-button{padding: 10px; font-size: 13px; width: 110px; float: right;}
	.my-alerts td.first{padding-left:20px;}
	.my-alerts td.last{padding-right:20px;}
	.my-alerts td .grey-button{padding:10px; font-size: 13px;}

	/* === BOOKING FLOW === */
	.booking-flow .left-container.confirmation-details .passenger-details .passenger-1{border-bottom:1px solid #ececec; border-right:0px;}
	.booking-flow .left-container.confirmation-details .passenger-details .heading{padding:20px;}
	.booking-flow .left-container.confirmation-details .passenger-details table th{padding:0px 10px 0px 20px;}
	.booking-flow #calendar .fc-basic-view .fc-body .fc-row{min-height: 60px;}
	.booking-flow #calendar .fc-basic-view td.fc-week-number span, .booking-flow #calendar .fc-basic-view td.fc-day-number{padding:5px 5px 0px 5px;}
	.booking-flow #calendar .fc-event, .booking-flow #calendar .fc-event:hover, .booking-flow #calendar .ui-widget .fc-event{font-size:15px;}
	.booking-flow .left-container.payment-details .card-details{border-right:none;}
	.booking-flow .left-container.payment-details .card-details .col{padding:0px;}

	/* === CONTACT PAGE === */
	.contact-form .col{padding:0px;}
	.inner-question:first-of-type{padding:10px 25px;}
	.inner-question{margin-left:25px; margin-right:25px;}

	/* === FAQ === */
	.faq .contact-container .col{padding:0px;}
	.faq .contact-container .first{margin-bottom:10px;}
	.faq .contact-container .first, .faq .contact-container .last{width:100%;}

	/* === FOOTER === */
	footer.site-wrapper{padding-top:10px; padding-bottom:5px;}
	footer .footer-logo{margin-bottom:10px; margin-top:15px; max-height:50px; width: 28px;}
	footer .link-container{padding:5px;}
	footer .link-container .col{margin-bottom:20px; width:33.33%; float:left; padding:0;}
	footer .link-container p{line-height: normal; margin:0px;}
	footer .social-links{margin:0 auto; ;} 
	footer .social-links .tw img {width:20px;}
	footer p.copyright{float:none;}
	footer ul.right{float:none;}
	footer ul.right li{margin:0px 10px 0px 0px;}
	
	footer ul li a{font-size:15px;}
	footer .iata-logo {
		margin: -6px 0 0 0;
		float: right;
		height: 30px;
		width: 90px;
	}
	footer p.copyright {margin:0; font-size:12px;}
	
	/* === FLIGHT DETAILS BOX === */
	.flight-details-box {padding:10px 5px;}
	.flight-details-box .flight-details-box-content {padding: 3px 0px; overflow-x: hidden;}
	
	.booking-flow .left-container .passenger-inputs .frequent-flyer-inputs {padding:15px 0 10px;}
	
	.question .inner-question i {display:none !important;}
	
	/* === GLOBAL FLIGHT DETAILS NAV === */ 
	.global-flight-details-nav .yellow-button {text-align:center; margin:5px 0 0 0;}
	.global-flight-details-nav .global-person-choose {clear:both; margin:5px 0 0 0; width:100%;}
	
	.departure-board-container .flight-container .flight-summary .price .per-off {display:none;}
	
}


@media  (max-width: 730px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col.departure {width:60%;}
	/* .departure-board-container .yellow-header .header-container  .col.date {width:40%;} */
	.departure-board-container .yellow-header .header-container  .col.departure p {margin:0 5px 0 0px;}
	.departure-board-container .yellow-header .header-container  .col .filters-trigger p {margin:6px 5px 0 0;}
	.departure-board-container .yellow-header .header-container  .col button.btn-filters span:not(.search-icon) {display:none;}
	.departure-board-container .flight-container .flight-summary p, .departure-board-container .flight-container .flight-summary .row .col.price .price {font-size: 13px;}
	.departure-board-container .board-titles .sort-by {margin: 9px 0 0 2px;} 
	
	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {margin-left: 54px}

	.departure-board-container .board-titles  .tooltip-hover {margin:2px 0 0 1px;}
	
	.global-flight-choose .information p {font-size:13px; margin:20px 0 0 0;}
	.global-flight-choose .information .icon img {top:18px;}
	.global-flight-choose .information .icon.dual img.departure {top:12px;}
	.global-flight-choose .information .icon.dual img.return {top:28px;}
	.global-flight-choose .information .departure .color-1, .global-flight-choose .information .arrival .color-1 {width:27px;}
	
	.global-flight-choose .labels p {font-size:13px;}
	.global-flight-choose .labels {height:35px;}
	.global-flight-choose .labels p {margin:12px 0 0 0;}
	.global-flight-choose .labels .col {height:30px;}

	.departure-board-container .board-titles .col-xs-6 { width:24.5% !important; }
}

@media  (max-width: 690px) {
	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-choose  .col.airline {width:23.833333%;}
	.global-flight-choose  .col.departure {width:16.5%;}
	.global-flight-choose  .col.arrival {width:18.666667%;}
	.global-flight-choose  .col.stops {width:12.5%;}
	/* .global-flight-choose .col.duration {display:none;} */
	.global-flight-choose  .col.price {width:15.5%;}

	/* .departure-board-container .more-flights {width:100%; text-align:center; margin-top:0;} */

	.mob-remove-33 {display:none !important;}
}

@media  (max-width: 680px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-details-container .left-container .from-label, .departure-board-container .flight-container .flight-details-container .left-container .to-label{display: block;}
	.time-line{display: none;}
	.departure-board-container .flight-container .flight-details-container .left-container .duration-details p{margin-left:0px;}
	
	/* === BOOKING FLOW === */
	.booking-flow .left-container .flight-info-container .flight-details .inner{margin-left:22px;}
	.booking-flow .left-container .flight-info-container .flight-details .details{width:100%;}
	.booking-flow .left-container .flight-info-container .details .from-label, .booking-flow .left-container .flight-info-container .details .to-label{display: block;}
	.booking-flow .left-container h3 span{display: block; margin-left:0px; margin-top:5px; font-size:11px;}
	.booking-flow .left-container .flight-info-container .duration-details p{margin-left:0px;}
	.info-box-container > p{position: relative; bottom:0px; right:0px;}

	/* === EDITORIAL PAGE === */
	.editorial-page .left-container .flight-info-container .details .from-label, .editorial-page .left-container .flight-info-container .details .to-label{display: block;}
	.editorial-page .left-container .flight-info-container .flight-details .inner{margin-left:20px;}

	/* === MY ACCOUNT === */
	.my-bookings .reference{display: none;}
	.my-bookings .total{display: none;}
	
	/* === GLOBAL FLIGHT DETAILS === */
	.flight-details-box .box-title {font-size:14px;}
}

@media  (max-width: 640px) {
	.price-breakdown h5 span,.mob-remove-4 {display:none !important;}
	.mob-show-3 {display:inline-block !important;}
	
	.booking-flow .left-container .header h4, .booking-flow .left-container .sub-header h5 {font-size:16px;}
	.booking-flow .left-container .main-header h4 {position:relative; top:-2px;}
	.booking-flow .left-container .main-header .icon.return2 {width:22px; top:24px;}
	.booking-flow .left-container .main-header .icon.check {width:22px; top:19px;}
	.booking-flow .left-container .main-header .icon.payment {width:24px; top:22px;}
	.booking-flow .left-container .main-header .icon.departure {width:22px; top:23px;}
	.booking-flow .left-container .main-header h4 {margin:0 0 0 7px;}
	.booking-flow .left-container .main-header div.info {font-size:12px;}
	.booking-flow .left-container .main-header div.info span {width:16px; height:16px; font-size:10px;}
	.booking-flow .left-container .main-header .time {top:21px;}
	
	.text-page .main-header .glyphicon {font-size:18px; top:14px;}
	
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col.date button.btn {font-size:0; height:42px; width:42px; margin-top:0px;}
	.departure-board-container .yellow-header .header-container  .col.date button.btn .glyphicon-filter {font-size:20px;}
	.departure-board-container .yellow-header .header-container  .col button.btn-filters {padding:10px 0px 11px !important; margin-right: -5px;}	.departure-board-container .flight-container .flight-summary .row .col.destination p.tomorrow {font-size:10px;}
	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {font-size:10px;}
	
	/* .departure-board-container .yellow-header .header-container  .col button.btn-filters span.icon-remove-bar {display:inline;} */
	
	/* === FOOTER === */
	footer .right{float:none;}
	footer ul{margin:0;}
	
	/* === GLOBAL FLIGHT DETAILS NAV === */ 
	.global-flight-details-header h4 {font-size:18px; padding: 0 0 0 27px;}
	.global-flight-details-header img {width:20px; height:auto; top:3px;}
	.global-flight-details-header .time {top:1px;}
		
	/* === GLOBAL FLIGHT DETAILS === */
	.price-breakdown h5 {font-size:14px;}
	.price-breakdown p {font-size:14px;}
	.price-breakdown .cost p {font-size:14px;}
	.price-breakdown .info p {font-size: 11px;}
	
	.booking-flow .info-message p {float:none;}
	
}


@media  (max-width: 625px) {
	.site-container {padding:0;}
	header .site-container {padding:0 20px;}

	.global-flight-details .left-text p span:not(.strong):not(.mobile) { font-size: 13px;}
}


@media  (max-width: 605px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col .dropdown-menu > li > a {padding-left:7px; padding-right:7px;}
	
	/* === GLOBAL FLIGHT DETAILS */
	.global-flight-details-nav {margin:0;}
	
	/* === GLOBAL FLIGHT DETAILS === */
	.price-breakdown .info {display:none;}
	.price-breakdown .info, .price-breakdown .cost {width:100%;}
	.price-breakdown .info p {text-align:right;}
	.price-breakdown .cost p  span {margin:0;}
	
	/* .price-breakdown .taxes {width:14.66666667%;}
	.price-breakdown .total {width:14.5%;} */
}

@media  (max-width: 600px) {
	.booking-flow .left-container .main-header div.info {display:none;}
	.mob-remove-5 {display:none !important;}
	.mob-show-4 {display:inline-block !important;}

	/* === INFO BOX === */
	.info-box table th{font-size:14px; padding:4px 5px 4px 0px; font-weight:500; min-width:120px;}
	.info-box table td{font-size:14px; padding:4px 0px 4px 5px; font-weight:500;}

	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-details-container .left-container .airline-details{display:none;}
	.departure-board-container .flight-container .flight-details-container .left-container .mob.airline-details{display:block;}
	.departure-board-container .flight-container .flight-details-container .left-container .airline-details .aireline-logo{margin-top:-5px;}
	.departure-board-container .flight-container .flight-details-container .left-container .header h4{margin-left:40px;}
	.departure-board-container .flight-container .flight-details-container .left-container .header i.icon-planeright{font-size:50px; top:-18px; line-height:normal;}
	.departure-board-container .flight-container .flight-details-container .right-container.passengers .header h4{margin-left:45px;}
	.departure-board-container .flight-container .flight-details-container .right-container.passengers .header i.flaticon-multiple25{font-size: 32px; top:-14px;}
	
	.departure-board-container .yellow-header .header-container  .col .dropdown-menu > li > a.child {padding-left: 25px;}
	
	/* === WHERE WE FLY PAGE === */
	.banner-wrapper.where-we-fly{height:320px;}

	/* === MY ACCOUNT === */
	.my-bookings .total{display: none;}
	.my-account h3{margin:30px 0px;}

	/* === DEPARTURE BOARD === */
	.editorial-page .left-container .flight-info-container table.direct .airline-details{display:none;}
	.editorial-page .left-container .flight-info-container table.direct .mob.airline-details{display:block;}

	/* === EDITORIAL PAGE === */
	.booking-flow .left-container .flight-info-container .airline-details{display: none;}
	.booking-flow .left-container .flight-info-container .mob.airline-details{display: block;}
	
}

@media  (max-width: 590px) {

	.departure-board-info {
		margin: 0px 0 45px 0;
	}

	.departure-board-info h1 {
		font-size: 6vw;
	}
	.departure-board-info span {
		font-size: 3.8vw;
	}
	
	/* === DEPARTURE BOARD === */
	.departure-board-container .date-info {background-color: var(--light-gray);}
	.departure-board-container .filter-area .filters-buttons-box .col-lg-4.last-row {margin-bottom:0px;}	
	/* .departure-board-container .more-flights {background-color: rgba(17,17,17,0.90) !important; color:#ffd00d !important; font-size:16px; padding-top:12px;} */
	/* .departure-board-container .more-flights span {color:#ffd00d !important; font-size:14px;} */
	.departure-board-background	{
		background-image: url(/assets/img/backgrounds/home-banner-mobile.jpg);
		height: 180px;
	}

	.col {padding-left:5px; padding-right:5px;} 

	.global-flight-details .graph .row {margin-left:0px; margin-right:0px;}
}

@media  (max-width: 580px) {
	/* === BOOKING FLOW === */
	.booking-flow .tab-header .tabs li a span{width:100%; font-size:11px; margin:-4px 0 -8px;}
	.booking-flow .tab-header .tabs li a i {top:0; margin:0 auto; float:none;}
	.booking-flow .tab-header .tabs li a {padding:4px 2px 0px;}

	.site-container {padding:0px;}
	header .site-container {padding: 0 20px;}
	
	/* === COOKIES INFO === */
	.cookies-info {padding:5px 16px;}
	.cookies-info p {font-size:13px;}
	.cookies-info span.close-cookies {font-size:13px;}
	
	.global-flight-choose {margin-left:-7px; margin-right:-7px;}
}

@media  (max-width: 560px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.destination span.time {margin-right:25px;}

	.global-flight-details .left-text p span:not(.strong):not(.mobile) { font-size: 13px;}
	.global-flight-details .right-text p span.terminal { font-size: 12px;}
	.global-flight-details .details ul.info { font-size: 13px}

	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {margin-left: 54px}
}

@media (min-width:550px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .filter-area button.clear-filters.active.desktop-hide {display:none !Important;}
}

@media (max-width: 590px){
    .mob-remove-borders {
        border: none !important;
    }
}

@media  (max-width: 550px) {
	.mob-remove-3 {display:none !important;}
	.mob-show-2 {display:inline-block !important;}
	
	/* === DEPARURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col.date{text-align: left;}
	.departure-board-container .yellow-header .header-container  .col p{font-size:15px;}
	.departure-board-container .yellow-header .header-container  .col.location select, .departure-board-container .yellow-header .header-container  .col.time select{font-size:14px;}
	.departure-board-container .yellow-header .selectpicker-wrapper .flaticon-agree{font-size:12px; right:-2px; position:relative;}
	.departure-board-container .board-titles p .mob-hide{display: none;}
	.departure-board-container .board-titles p .mob-view{display: inline-block;}
	.departure-board-container .number-of-flghts-container{padding:7px 15px;}
	.departure-board-container .number-of-flghts-container .note a{margin-left:20px;}
	.number-of-flghts-container .note{float:none;}
	.number-of-flghts-container .sort-by-container{margin-top:7px; float: none;}
		
	.departure-board-container .yellow-header .header-container  .col .filters-trigger p {margin:0px 5px 0 0;}
	.departure-board-container .yellow-header .header-container  .col .dropdown-menu > li > a {padding-left:4px;}
	
	.departure-board-container .filter-area button.clear-filters {margin:9px 0 7px; border: none; font-size: 14px; padding: 4px 10px; background-color: #dddddd; display:none; text-align:center;}
	.departure-board-container .filter-area button.clear-filters.active { background-color:#ffd010; border-color: #ffd010; }
	.departure-board-container .filter-area button.clear-filters.active:hover { background: #fcdd53 !important;}
	
	/* .departure-board-container .yellow-header .header-container  .departure .selectpicker-icon {top:13px; left:6px; height:17px;} */
	.departure-board-container .yellow-header .header-container  .departure button {padding-left:25px;}
	/* .departure-board-container .selectpicker-wrapper .flaticon-agree:before {content: "\e252"; font-family: 'Glyphicons Halflings'; font-size: 10px;} */
	.departure-board-container .yellow-header .header-container  .departure button {padding-right:21px;}

	.departure-board-container .board-titles .sort-by.active-up, .departure-board-container .board-titles .sort-by.active-down {margin: 9px 0 0 3px;}
	.departure-board-container .board-titles .sort-wrapp.time {margin-right:2px;}
	.departure-board-container .flight-container .flight-summary p.one {padding-left:14px;}
		
	/* === BOOKING FLOW === */
	.booking-flow #calendar .fc-toolbar{padding:10px;}
	.booking-flow #calendar .fc-toolbar button{font-size:22px;}
	.booking-flow #calendar .fc-toolbar h2{margin-top:11px;}
	.booking-flow #calendar .fc-basic-view .fc-body .fc-row{min-height: 50px;}
	.booking-flow #calendar .fc-basic-view td.fc-week-number span, .booking-flow #calendar .fc-basic-view td.fc-day-number{padding:3px 3px 0px 3px; font-size:10px;}
	.booking-flow #calendar .fc-event, .booking-flow #calendar .fc-event:hover, .booking-flow #calendar .ui-widget .fc-event{font-size:14px;}
	.booking-flow .left-container.passenger-details a.yellow-button.login p{margin-right:0px;}
	.booking-flow .left-container.passenger-details a.yellow-button.login i {display: none;}

	/* === MY ACCOUNT === */
	.my-bookings .journey{display: none;}
	.my-bookings .date{vertical-align: middle;}
	.my-bookings td, .my-bookings th{padding-top:10px; padding-bottom:10px;}

	/* === CONTACT PAGE === */
	.inner-question:first-of-type{padding:15px 15px;}
	.inner-question{padding:5px 0px;}
	.inner-question{margin-left:15px; margin-right:15px;}
	.question{padding:5px 0px;}

	/* === EDIT ACCOUNT === */
	.my-account .personal-details th, .my-account .personal-details td{width:100%; display: block; padding:0px 20px 5px 20px;}
	.my-account .personal-details table tr.first td{padding-top:5px;}
	
	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-choose  .col.airline {display:none;}
	.global-flight-choose  .col.departure {width:22.5%;}
	.global-flight-choose  .col.arrival {width:27.666667%;}
	.global-flight-choose  .col.stops {width:15.5%;}
	.global-flight-choose  .col.price {width:21.5%;}
}

@media  (max-width: 530px) {
	.booking-flow .left-container {padding: 0 10px 10px;}
	
	/* === DEPARTURE BOARD === */
	 .departure-board-container .date-info p, .departure-board-container .filter-area button, .departure-board-container .filter-area .selections ul li a, .departure-board-container .filter-area .clear-filters-box a span {font-size:15px;} 
	/* .filters-box ul li label{font-size: 14px;} */

	.departure-board-container .filter-area .clear-filters-box img {margin: 6px 0 0 15px;}

	.departure-board-container .yellow-header .header-container  .col .filters-trigger p {margin:0 5px 0 0 !important;}
	.departure-board-container .yellow-header .header-container  .col.departure p {  margin-top:0px !important;}
	.departure-board-container .yellow-header .header-container  .departure button {height:34px;}
	/* .departure-board-container .yellow-header .header-container  .col .dropdown-menu > li > a {font-size:17px;}
	.departure-board-container .yellow-header .bootstrap-select.btn-group .dropdown-menu li small {font-size:17px;} */
	.departure-board-container .filter-area .selections ul li img {top:7px;}
	.departure-board-container .yellow-header .header-container  .col.date button.btn {font-size:0 !important; height:34px; width:34px;}
	/* .departure-board-container .yellow-header .header-container  .col button.btn-filters span {top:7px;} */
	/* .departure-board-container .yellow-header .header-container  .departure .selectpicker-icon {top:13px; left:6px; height:14px;} */
	.departure-board-container .yellow-header .header-container  .departure button {padding-left:19px;}	
	/* .departure-board-container .yellow-header .header-container  .col button.btn-filters span.icon-remove-bar {top:6px;} */
	
	.global-info-wrapp p {font-size: 11px;}
	.global-info-wrapp p .icon {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	
	.global-flight-choose-wrapp .global-flight-details-wrapp {padding:0 8px;}
	.departure-board-container .yellow-header .header-container  .departure .selectpicker-wrapper {width: calc(100% - 62px);}
}

@media (max-width: 510px) {
	.dep-time i{
        position: absolute; 
        right: 3px;
    }
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.destination p.tomorrow {top:14px;}
	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {top:14px;}
	.departure-board-container .filter-area button {height: 42px;}
	
	.filters-box input[type="submit"] {padding:10px;}
}

@media (max-width: 500px) {
	header.site-wrapper {padding:5px;}
	header .logo-subtitle {bottom:6px; font-size:13px; margin:0 0 0 5px;}

	a.menu-butt {margin-right:4px;}
	
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.price .direction {font-size:12px; color:#fff;}
	.departure-board-container .flight-container .flight-summary.active .row .col.price .direction {color:#111111;}
	.departure-board-container .yellow-header .header-container  .col .filters-trigger p { margin-top: 0px !important}
	.departure-board-container .yellow-header .header-container  .col.date button.btn { margin-top: 0px !important}
	.departure-board-container .flight-container .flight-summary p {margin:15px 0 0 0;}
	.departure-board-container .yellow-header .header-container  .col.departure p {margin-top:0px !important;}
	.departure-board-container .showing p {font-size:11px; top:9px; padding-top:8px;}
	.flight-container .flight-details-container{ padding-bottom:5px;}
	.tooltip-hover {width:16px; height:16px; font-size:11px; margin: 4px 0 0 5px;}
	
	.departure-board-container .filter-area {padding:10px 5px 2px;}
	.departure-board-container .filter-area .container-fluid {padding-left:15px; padding-right:15px;}
	
	.global-flight-details .left-clock p, .global-flight-details .stop .additional p span {font-size:11px;}
	.global-flight-details .left-clock img.clock-img, .global-flight-details .stop .additional img.clock-img { height:13px; margin-right:4px;}
	.global-flight-details .left-clock img.clock-img {margin-right:2px;}
	.global-flight-details .stop .additional img.clock-img  {top:0;}
		
	.booking-flow .left-container .main-header .icon.passenger {top:17px;}

	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-details .left-text p, .global-flight-details .right-text p, .global-flight-details .left-text p span {font-size:13px;}
	.global-flight-details .left-text p span:not(.strong) { font-size: 13px;}
	.global-flight-details-header h4 {font-size:15px; margin-bottom:0 !important; position:relative; top:-1px;}
	.global-flight-details-header .time {top:-4px;}
	.global-flight-details-header h4 i {top:2px; margin:0px -2px;}
	.global-flight-details-header {margin: 0 0 6px 0;}
	
	.global-flight-details .icon .wrapp img {width:23px; margin-top:7px;}
	.global-flight-details .icon .wrapp img {height:19px;} 
	.global-flight-details  .row:nth-of-type(3) .icon .wrapp img {height:19px;}
	
	.global-flight-details .flight .hide-phone {display:none;}
	.global-flight-details .flight .show-phone {display:inline;}


	.flight-details-container .container-fluid {padding-left:0px; padding-right:0px;}

	.global-flight-details .details .airline-icon { display: none; width: unset !important;}
	.global-flight-details .details .leg-info { width: 100% !important;}
	.global-flight-details .details .leg-info ul { padding: 0px !important; font-size: 12px;}
	/* .global-flight-details .details .leg-info ul li {margin-top: 0px; line-height: 17px;} */
	.global-flight-details .details .leg-info ul li {margin-top: 0px;}

	.global-flight-details .left-clock div {font-size:12px;}

	/* === COMMON === */
	h1, h2, h3, h4{font-size:18px;}
	.yellow-header.page-header{height:70px;}
	.yellow-header.page-header h1{font-size:18px; margin-left:30px;}
	.pop-up-form-container .yellow-header h4{font-size: 18px; margin-top:7px;}
	.yellow-header.page-header .departure i.flaticon-earth94{font-size: 26px; top:17px;}
	.text-page.yellow-header.page-header i{font-size: 28px;}
	.yellow-header.page-header .departure i.flaticon-human5{font-size:26px; top:15px;}

	/* === FONT SIZES === */
	p{font-size:15px;}
	p.label, label{font-size: 15px;}
	.btn, .default-input, .default-textarea, .default-select select{font-size:15px;}

	/* === HEADER === */
	header .logo{width:auto; margin-top:5px;}
	header .logo-no-tag{width:144px; margin-top:4px;}
	.sub-menu{margin-top:12px;}
	header .logo img {height:35px;width: 118px;}
	/* .mobile-menu {margin-top:6px;} */
	.sub-menu ul li a {font-size:12px; padding: 13px 0;}
	
	header .site-container {padding:0 0 0 0 !important;}

	/* === POPUP FORM === */
	.pop-up-form-container .col-xs-12{width:100%;}

	/* === WHERE WE FLY === */
	.banner-wrapper.where-we-fly{background:#fff; height:auto;}
	.banner-wrapper.where-we-fly .banner-contents{top:0; -webkit-transform:none; -ms-transform:none; transform:none;}
	.banner-contents .where-we-fly-selection .col p{font-size: 15px;}

	/* === DEPARTURE BOARD === */
	.passenger-input-container label{margin-top:5px;}
	.departure-board-container .yellow-header .header-container  .col.location, .departure-board-container .yellow-header .header-container  .col.time{padding:11px 0px 0px 0px;}

	#filters-container .inner p.bold-text{font-size:13px;}

	.departure-board-container .flight-container .flight-details-container .left-container .header h4{margin-left:33px;}
	.departure-board-container .flight-container .flight-details-container .left-container .header i.icon-planeright{font-size:45px; top:-15px; line-height:normal;}
	.departure-board-container .flight-container .flight-details-container .right-container.passengers .header h4{margin-left:35px;}
	.departure-board-container .flight-container .flight-details-container .right-container.passengers .header i.flaticon-multiple25{font-size: 28px; top:-12px;}
	.departure-board-container .flight-container .flight-details-container .container .outer-border .inner{margin:15px;}

	.passenger-input-container .passenger-input input{margin:0px 5px;}
	.departure-board-container .number-of-flghts-container .note{font-size:13px;}

	.departure-board-container .load-more-container a p{width:auto; display: block;}
	.departure-board-container .load-more-container span{font-size:14px; margin-top:2px; float:none;}
	.departure-board-container .load-more-container i{font-size:18px; position: relative; top: 2px;}

	.sort-by-container {float:none;}
	.departure-board-container .number-of-flghts-container .sort-by-container, .departure-board-container .number-of-flghts-container .sort-by-container .bootstrap-select > .btn{font-size:13px;}

	/* === MY ACCOUNT === */
	.my-account .expand-link h1, .my-account .expand-link h2{font-size:16px; margin-top:12px;}

	/* === IMG CARD === */
	.img-card .card-content .inner{margin:20px 15px;}
	.img-card .card-content .inner h3{font-size:18px;}
	.img-card .card-content .inner td{padding:5px;}
	.img-card .card-content .inner .price-container{margin-top:20px;}
	.img-card .card-content .inner .price-container p{font-size: 18px;}
	.img-card .card-content .inner .price-container a{font-size: 27px; line-height: 30px;}

	/* === EDITORIAL PAGE === */
	.editorial-page .left-container .inner{margin-left:15px; margin-right:15px;}
	.editorial-page .right-bar .inner{margin:20px 15px 20px 15px;}
	.editorial-page .left-container .flight-info-container .flight-details .inner{margin:15px;}
	.editorial-page .left-container .outer-border .inner{margin:15px;}
	.editorial-page .right-bar .outer-border{padding:15px;}

	/* === BOOKING FLOW === */
	.booking-flow .left-container .inner.header{margin:0px 15px;}
	.booking-flow .left-container.passenger-details a.yellow-button.login p{font-size:16px;}
	.booking-flow .left-container.passenger-details .yellow-header.create-account .header{font-size: 16px;}
	.booking-flow .left-container .inner.header, .pop-up-form-container .yellow-header{height:70px;}
	.booking-flow .left-container .inner{margin:0;}
	.booking-flow .left-container .inner.passenger-inputs .col{padding-left:0px; padding-right:0px;}
	.booking-flow .left-container.confirmation-details .header .icon-tickcircle{top:-18px; left:-2px;}
	.booking-flow .left-container.payment-details .header .flaticon-credit-card21{top:-14px; left:-2px; font-size: 28px;}
	.booking-flow .left-container.confirmation-details .header .flaticon-check74{font-size:28px; top:-15px;}
	.booking-flow .left-container.confirmation-details > .inner > .header > h4{margin-left:36px;}
	.booking-flow .left-container.confirmation-details > .inner.flight-info-container > .header > h4{margin-left:36px;}
	.booking-flow .left-container.confirmation-details .inner.payment-header i.flaticon-credit-card21{font-size:28px; top:-13px;}
	.booking-flow .left-container .inner.passengers .col{padding:15px 15px 0px 15px;}
	.booking-flow .left-container .inner.passengers .col.last{padding:15px;}
	.booking-flow .left-container .flight-info-container .flight-details .inner{margin: 15px;}
	.booking-flow .left-container .header i.flaticon-multiple25{top:-12px;}
	.booking-flow .left-container .header i{top:-36px;}
	.booking-flow .left-container .header > i{font-size:45px; top:-22px;}
	.booking-flow .left-container .header i.flaticon-multiple25{font-size:28px;}
	.booking-flow .left-container.passenger-details .header h4{margin-left:40px;}
	/* .booking-flow .left-container .main-header h4{margin-left:25px;} */
	.booking-flow .left-container .outer-border .inner{margin:15px;}
	.booking-flow #calendar .fc-basic-view .fc-body .fc-row{min-height:52px;}
	.booking-flow #calendar .fc-event, .booking-flow #calendar .fc-event:hover, .booking-flow #calendar .ui-widget .fc-event{font-size:13px;}
	.booking-flow .left-container.confirmation-details .passenger-details .heading{padding:15px;}
	.booking-flow .left-container.confirmation-details .passenger-details table th{padding-left:15px;}
	.booking-flow .left-container.passenger-details a.yellow-button.login{padding:15px;}
	.booking-flow .left-container.passenger-details a.yellow-button.login p{margin-right:0px;}
	.booking-flow .left-container.passenger-details .yellow-header.create-account{padding:15px 15px 5px 15px;}
	
	
	/* === GLOBAL FLIGHT DETAILS NAV === */ 
	.global-flight-details-header img {top:-3px !important;}
	.global-flight-details-header .time img {top:4px !important;}
	
	/* === GLOBAL FLIGHT DETAILS === */
	.price-breakdown h5 {font-size:13px;}
	.price-breakdown p {font-size:13px;}
	.price-breakdown .cost p {font-size:13px;}
	
	/* .price-breakdown .taxes {width:12.66666667%;} */
	/* .price-breakdown .subtotal {width:14.5%;} */
	
	.booking-flow .left-container .main-header.details h4 {font-size:14px; margin-left:30px;}
	.booking-flow .left-container .main-header.details .icon.departure {top:22px;}
	.booking-flow .left-container .main-header.details .icon.return2 {top:22px;}
	.booking-flow .left-container .main-header .time {top:20px;}	
	/* .booking-flow .flight-details-container .container-fluid {padding-left: 15px; padding-right: 15px;} */
	.booking-flow .flight-details-container {padding-left: 17px; padding-right: 17px;}
	
	.text-page .main-header .glyphicon {top:11px;}
	
	.global-flight-choose .information .arrow i {font-size:15px; top:18px;}

	.departure-board-container .more-flights {height:42px !important; padding-top: 5px !important;}
	/* .departure-board-container .more-flights span {font-size: 15px !important;} */
	.departure-board-container .yellow-header .header-container  .col button.btn-filters {padding-right: 1px !important; padding-top: 7px !important;}
}

@media  (max-width: 480px) {
	
	.global-flight-details .stop span.hide-mobile  {display:none;}
	
	/* === DEPARTURE BOARD === */	
	.departure-board-container .flight-container .flight-summary .price .per-off {top:23px;}

	.departure-board-container .flight-container .flight-summary .row .col.price p .was-price {position: absolute; left:5px; top:26px; font-size:10px;}
	.departure-board-container .flight-container .flight-summary .row .col.price .price{font-size: 13px; float:left; margin:0 3px 0 0;}
	.departure-board-container .flight-container .flight-summary .row .col.price p .direction  { font-size:11px;}
   
	.departure-board-container .col.dep-time, .departure-board-container .col.price {width:21%;}
	.departure-board-container .col.dep-time, .departure-board-container .col.board-title-take-off {width:28%;}
	.departure-board-container .flight-container .flight-summary .price .per-off {display:inline; border:none; font-size:10px; position:absolute; top:24px; left:24px;}
	.departure-board-container .flight-container .flight-summary .price .per-off.shorter {left:19px;}
	
}

@media  (max-width: 450px) {

	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .col.departure {width:65%;}
	/* .departure-board-container .yellow-header .header-container  .col.date {width:35%;} */
	/* .departure-board-container .yellow-header .header-container  .col.departure p {display:inline-block; font-size: 17px;} */
	
	.passenger-input-container .passenger-input .passenger-button {height:40px; width:40px;}
	.passenger-input-container .passenger-input input {max-width:40px; padding:8px 0 9px;}
	.passenger-input-container .passenger-input .passenger-button span {top:-1px;}
	
	.passenger-input-container .passenger-input .passenger-button.dec span {top:-13px;}
	
	.filters-box .filters-box-nav button {height:40px;}
	.filters-box ul li label {margin-bottom:13px;}
	.departure-board-container .filter-area .filters-box .filters-box-search {height:40px;}
	.departure-board-container .filter-area .filters-box .filters-box-search-icon {top:14px;}
	.filters-box {top: 50px;}
	
	/* .booking-nav .col {width:100%;} */
	/* .booking-nav .col .yellow-button.back {text-align:right; padding:15px 20px 15px 55px; margin:0 0 10px 0;} */

	.departure-board-container .board-titles .board-title-stops .sort-wrapp {position:absolute;}
}

@media  (max-width: 420px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .yellow-header .header-container  .departure .selectpicker-wrapper {width: calc(100% - 45px);}
	.departure-board-container .yellow-header .header-container  .departure .bootstrap-select {width:100%;}
	.departure-board-container .yellow-header .header-container  .departure .selectpicker-icon {display:none;}
	.departure-board-container .yellow-header .header-container  .departure button {padding-left: 5px;height: 33px;margin-top:3px;}
		
	.go-back-calendar {margin:15px 0 0 -7px;}
	
	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-choose  .col.departure {width:22.5%;}
	.global-flight-choose  .col.arrival {width:27.666667%;}
	.global-flight-choose  .col.stops {width:13.5%;}
	.global-flight-choose  .col.price {width:23.5%;}

	.global-flight-details .icon {padding-left:10px;}
	
	.global-flight-choose .information .icon img {width:17px;}
	.global-flight-choose .information .icon img {top:20px;}
	.global-flight-choose .information .icon.dual img.departure {top:14px;}
	.global-flight-choose .information .icon.dual img.return {top:29px;}
}

@media  (max-width: 405px) {
	.mob-remove-7 {display:none !important;}
	.departure-board-container .yellow-header .header-container  .col.departure {width:60%;}
	/* .departure-board-container .yellow-header .header-container  .col.date {width:40%;} */

	/* === GLOBAL FLIGHT DETAILS === */
	.price-breakdown h5 {font-size:12px;}
	.price-breakdown p {font-size:12px;}
	.price-breakdown .cost p {font-size:12px;}
	
	.price-breakdown .number {width:14.5%;}
	.price-breakdown .total {width:12.5%;}
	.price-breakdown .fare {width:10.5%;}
	 
	.price-breakdown .col {padding:0 3px;}
	
	.global-flight-choose .header .details {font-size:13px;}
	.global-flight-details  .col-xs-12.additional span {position:absolute;}
}

@media  (max-width: 385px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.destination p {position:absolute;}
}

@media  (max-width: 360px) {
	.departure-board-container .flight-container .flight-summary p.one {padding-left:11px;}
	.departure-board-container .flight-container .flight-summary .row .col.destination span.time {margin-right: 20px;}
	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {margin-left: 49px;}
}

@media (max-width: 355px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary p {font-size:12px;} 
	.departure-board-container .flight-container .flight-summary p {margin:16px 0 0 0;}
	
	.global-flight-choose p {font-size:12px;}
}

@media  (max-width: 350px) {
	.global-flight-details  .additional span span {display:none;}

	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.price .was-price {font-size:10px;}
	.tooltip-hover {display:none;}
	
	.global-flight-details  .additional span {font-size:11px; margin:0 0 0 5px; top:14px;}
	.global-flight-details  .additional img {top:10px;}
	.global-flight-details .left-text p, .global-flight-details .right-text p, .global-flight-details .left-text p span, .global-flight-details .color1.small {font-size:11px;}

	.mob-remove-6 {display:none !important;}
	.mob-show-5 {display:inline-block !important;}
	
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.price .direction {font-weight:100; font-size: 11px;}
	
	.departure-board-container .flight-container .flight-summary .row .col.destination p.tomorrow {font-size:9px; top:10px;}
	.departure-board-container .flight-container .flight-summary .row .col.destination p.country {font-size:9px; top:10px; margin-left: 39px;}
	.departure-board-container .flight-container .flight-summary .row .col.price p .direction {font-size:9px;}
	.departure-board-container .flight-container .flight-summary .price .per-off {font-size:9px;}
	.departure-board-container .showing p, .departure-board-container .board-titles p, .departure-board-container .date-info p {font-size:14px;}
	.departure-board-container .flight-container .flight-summary p, .departure-board-container .board-titles p {font-size:10px;}
	.departure-board-container .flight-container .flight-summary .row .col.price .price {font-size:10px;}
	.departure-board-container .board-titles .sort-by {display:none;}
	

	/* === GLOBAL FLIGHT DETAILS === */
	.global-flight-details .col {padding:0;}
	.global-flight-details .col.mobile-info {padding:0 12px 0 0;}
	
	header .logo-subtitle {display:none;}	
	
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary .row .col.dep-time i {font-size:9px; margin-top:21px;}
	.departure-board-container .flight-container .flight-summary p {margin-top:18px;}
	.departure-board-container .board-titles .sort-wrapp.time {margin-right:14px;}
	
	/* === GLOBAL FLIGHT DETAILS === */

	.global-flight-choose  .col.departure {width:27.5%;}
	.global-flight-choose  .col.arrival {width:32.666667%;}
	.global-flight-choose  .col.stops {width:13.5%;}
	.global-flight-choose  .col.price {width:13.5%;}
	
	.global-flight-choose  .col.price span.color-1 {width:100%; display:table; font-size:11px; margin-top:-5px;}
}

@media  (max-width: 315px) {
	/* === DEPARTURE BOARD === */
	.departure-board-container .flight-container .flight-summary p, .departure-board-container .flight-container .flight-summary .row .col.price .price, .departure-board-container .board-titles p, .departure-board-container .flight-container .flight-summary .row .col.price .direction {font-size:9.5px;}
	.tooltip-hover {display:none;}
	.departure-board-container .yellow-header .header-container  .col .filters-trigger p { margin: 11px 10px 0 0 !important; font-size: 11px;}
	.departure-board-container .yellow-header .header-container  .col.departure p {margin-top:11px !Important;font-size: 11px;}
	.departure-board-container .yellow-header .header-container  .departure .selectpicker-wrapper {width:100%;}
	.departure-board-container .yellow-header .header-container  .col.departure p {display:none;}
	.departure-board-container .yellow-header .header-container  .col.departure, .departure-board-container .yellow-header .header-container  .col.date {width:50%;}
}

@media(min-width:501px) and (max-width:1130px) {
	.booking-flow .tab-header .tabs li a span {font-size: 14px !important;}
	.global-flight-details .timeline .dot {width:25px; height: 25px;}
}

@media(min-width:375px) and (max-width:500px) {
	.yellow-button {font-size: 15px;}
	/* .booking-flow .tab-header .tabs li a p span {font-size: 17px !important;} */
	.booking-flow .left-container.passenger-details .passenger-inputs p, 
	.booking-flow .left-container.passenger-details .passenger-inputs input, 
	.booking-flow .left-container.passenger-details .passenger-inputs .selectpicker.btn-default, 
	.booking-flow .left-container.passenger-details .passenger-inputs 
	.dropdown-menu{font-size: 15px !important;}
	.departure-board-container .more-flights { font-size: 15px !important;}

	/* .price-breakdown .number {width:4.5% !important;} */
	/* .price-breakdown .total {width:17.5%;} */
	/* .price-breakdown .fare {width:16.5%;} */
	/* .price-breakdown .taxes {width:17.66666667%;} */
	/* .price-breakdown .type {width:11%;} */
	/* .price-breakdown .services {width:14.833333%;} */
	/* .price-breakdown .subtotal {width:16.5%;} */
	/* .n-header-grid-wrapper .n-stops {width: 49px;} */
	.booking-flow .left-container.confirmation-details .reference-number .note {font-size: 15px;}
}

@media(min-width:360px) and (max-width: 374px) {
	.yellow-button {
		font-size: 14px;
	}

	.departure-board-container .board-titles .sort-by{
	  margin-top: 5px !important;
  } 
  .departure-board-container .more-flights {
	  font-size: 14px !important;
	  height: 40px !important;
  }

  .global-flight-choose .labels p {
	  margin-top: 1px !important;
  }

  .booking-flow .left-container.passenger-details .passenger-inputs p, 
	.booking-flow .left-container.passenger-details .passenger-inputs input, 
	.booking-flow .left-container.passenger-details .passenger-inputs .selectpicker.btn-default, 
	.booking-flow .left-container.passenger-details .passenger-inputs 
	.dropdown-menu{font-size: 14px !important;}
.booking-flow .left-container.confirmation-details .reference-number .note {font-size: 14px;}
/* .price-breakdown .number {width:4.5% !important;}
.price-breakdown .total {width:17.5%;}
.price-breakdown .fare {width:16.5%;}
.price-breakdown .taxes {width:17.66666667%;}
.price-breakdown .type {width:11%;}
.price-breakdown .services {width:14.833333%;}
.price-breakdown .subtotal {width:16.5%;} */
}

@media(min-width:350px) and (max-width: 359px) {
	.yellow-button {
		font-size: 12px;
	}

	.departure-board-container .board-titles .sort-by{
	  margin-top: 5px !important;
  } 
  .departure-board-container .more-flights {
	  font-size: 12px !important;
	  height: 40px !important;
  }

  .global-flight-choose .labels p {
	  margin-top: 1px !important;
  }

  .booking-flow .left-container.passenger-details .passenger-inputs p, 
	.booking-flow .left-container.passenger-details .passenger-inputs input, 
	.booking-flow .left-container.passenger-details .passenger-inputs .selectpicker.btn-default, 
	.booking-flow .left-container.passenger-details .passenger-inputs 
	.dropdown-menu{font-size: 12px !important;}
.booking-flow .left-container.confirmation-details .reference-number .note {font-size: 12px;}
}